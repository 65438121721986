<template>
  <main id="privacy">
    <header class="header">
      <div class="container">
        <h1 v-scrollanimation class="title delay-1 fade-in-bottom">
          Privacidade
        </h1>
        <p v-scrollanimation class="delay-2 fade-in-bottom">
          Respeitamos seu espaço e não compartilhamos seus dados.
        </p>
      </div>
    </header>
    <section class="container">
      <p v-scrollanimation class="delay-1 fade-in-bottom">
        A <strong>Data Core Solutions</strong> valoriza sua privacidade e tem o
        compromisso de proteger e processar suas informações pessoais de forma
        responsável. Esta declaração de privacidade descreve como a Data Core
        Solutions coleta, utiliza e compartilha suas informações. A
        <strong>Data Core Solutions</strong> pode fornecer informações
        adicionais de privacidade de dados utilizando um aviso de privacidade
        complementar.
      </p>
      <p v-scrollanimation class="delay-1 fade-in-bottom">
        Para proteger suas informações pessoais de acesso, uso e divulgação não
        autorizados, a Data Core Solutions implementa medidas de segurança
        técnicas e administrativas. Essas medidas de segurança incluem controles
        de acesso baseado em função e criptografia para manter as informações
        pessoais privadas enquanto estiverem em trânsito.
      </p>
      <p v-scrollanimation class="delay-1 fade-in-bottom">
        Nós também exigimos que nossos Parceiros Comerciais, fornecedores e
        terceiros implementem as devidas medidas de segurança, tais como termos
        de contrato e restrições de acesso, para proteger as informações contra
        acesso, uso e divulgação não autorizados. A
        <strong>Data Core Solutions</strong> mantém informações pessoais apenas
        enquanto necessário para cumprir os propósitos para os quais elas foram
        processadas ou para cumprir requisitos legais e regulamentares de
        retenção. Os requisitos legais e regulamentares de retenção podem
        incluir a retenção de informações para:
      </p>
      <ul v-scrollanimation class="list square delay-1 fade-in-bottom">
        <li class="item">Propósitos de auditoria e contabilidade</li>
        <li class="item">Termos de retenção estabelecidos por lei</li>
        <li class="item">Tratamento de litígios</li>
        <li class="item">
          E o estabelecimento, exercício ou defesa de reivindicações legais em
          países nos quais a <strong>Data Core Solutions</strong> faz negócios
        </li>
      </ul>
      <p v-scrollanimation class="delay-1 fade-in-bottom">
        A <strong>Data Core Solutions</strong> mantém quaisquer informações de
        relação contratual para propósitos administrativos, requisitos legais e
        regulamentares de retenção, defendendo os direitos da empresa, e para
        gerenciar o relacionamento entre você e a
        <strong>Data Core Solutions</strong>. As informações fornecidas em um
        aviso de privacidade complementar podem fornecer informações mais
        detalhadas sobre os termos de retenção aplicáveis.
      </p>
      <p v-scrollanimation class="delay-1 fade-in-bottom">
        Quando as informações pessoais não são mais necessárias, nós utilizamos
        nossos processos em vigor para excluí-las com segurança, por exemplo,
        apagando arquivos eletrônicos e fragmentando registros físicos. O Modelo
        de Política de Privacidade da <strong>Data Core Solutions</strong> foi criado com base
        nas boas práticas de proteção de dados pessoais e seguindo as
        disposições da LGPD e demais normas pertinentes.
      </p>
    </section>
  </main>
</template>

<script>
export default {
  name: "Privacy",
};
</script>

<style lang="scss" scoped>
#privacy {
  h3.title {
    margin: 40px 0 20px;
    color: $PrimaryColor;
    text-transform: uppercase;

    @include respond(md-up) {
      margin: 80px 0 40px;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }
}
</style>