<template>
  <main class="unique_trainer">
    <header class="header">
      <div class="container">
        <h1 v-scrollanimation class="title delay-1 fade-in-bottom">
          Formatador Único
        </h1>
        <p v-scrollanimation class="delay-2 fade-in-bottom">
          Comunicações Mais Estruturadas e Entrega Mais Rápida.
        </p>
      </div>
    </header>
    <section class="section what">
      <div class="container">
        <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">
          O que é um Formatador Único?
        </h3>
        <div class="content d_flex">
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            É uma plataforma de gerenciamento de comunicações que cria,
            centraliza, personaliza e padroniza templates de e-mails e SMS em um
            só lugar. A alta performance da ferramenta permite que templates,
            que demorariam meses para ficar prontos, possam ser enviados em
            minutos. Entregue comunicações em diversos canais e obtenha
            relatórios para análise de entrega, status das mensagens e respostas
            dos clientes.
          </p>
        </div>
      </div>
    </section>
    <section class="section images">
      <ul class="list d_flex">
        <li class="item">
          <img
            v-scrollanimation
            class="delay-1 scale-in-center"
            src="../../assets/img/produtos/formatador_unico_img1.jpg"
            alt="Reuniões em Escritório"
          />
        </li>
        <li class="item">
          <img
            v-scrollanimation
            class="delay-2 scale-in-center"
            src="../../assets/img/produtos/formatador_unico_img2.jpg"
            alt="Trabalho em Notebook"
          />
        </li>
        <li class="item">
          <img
            v-scrollanimation
            class="delay-3 scale-in-center"
            src="../../assets/img/produtos/formatador_unico_img3.jpg"
            alt="Reuniões de Projetos"
          />
        </li>
      </ul>
    </section>
    <section class="section features">
      <div class="container d_flex">
        <div class="demo">
         <img v-scrollanimation class="icons-text delay-1 fade-in-bottom" src="../../assets/svg/formatador_unico_icon.svg" alt="Formatador Único">
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            Centralize a criação das comunicações de documentos digitais,
            e-mails e SMS e entregue de forma mais ágil com segurança e melhor
            experiência do cliente.
          </p>
        </div>
        <ul class="list">
          <li v-scrollanimation class="item delay-1 fade-in-bottom">
            <h4 class="title">Melhor Experiência</h4>
            <p>
              Comunicações em grande escala com entrega mais rápida, para
              diversos canais.
            </p>
          </li>
          <li v-scrollanimation class="item delay-2 fade-in-bottom">
            <h4 class="title">Omnichannel</h4>
            <p>
              De acordo com pesquisas do Conselho da OCM, 85% dos consumidores
              concordam que seu canal ideal é, na verdade, uma combinação de
              vários canais.
            </p>
          </li>
          <li v-scrollanimation class="item delay-3 fade-in-bottom">
            <h4 class="title">Centralização da Criação de Documentos</h4>
            <p>
              De acordo com pesquisas do Conselho da OCM, 85% dos consumidores
              concordam que seu canal ideal é, na verdade, uma combinação de
              vários canais.
            </p>
          </li>
          <li v-scrollanimation class="item delay-4 fade-in-bottom">
            <h4 class="title">Personalização</h4>
            <p>
              De acordo com pesquisas do Conselho da OCM, 85% dos consumidores
              concordam que seu canal ideal é, na verdade, uma combinação de
              vários canais.
            </p>
          </li>
        </ul>
      </div>
    </section>

    <!-- <ModalIframe 
      :src="'https://www.youtube.com/embed/m7Bc3pLyij0'"
      :title="'YouTube video player'"
      :addClass="demoActive ? 'active' : ''"
    /> -->
  </main>
</template>
<script>
// import Button from '../../components/common/Button.vue';
// import ModalIframe from '../../components/ModalIframe.vue';
export default {
  components: {
    // Button,
    // ModalIframe,
  },
  name: "UniqueTrainer",
  methods: {
    showDemo() {
      this.$store.commit("overlayStore/setOverlay", true);
      this.$store.commit("modalStore/setModalVisibility", true);
    },
  },
  computed: {
    demoActive() {
      return this.$store.getters["modalStore/getModalIsActive"];
    },
  },
};
</script>