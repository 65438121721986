<template>
  <footer id="footer">
    <div class="container d_flex">
      <div v-scrollanimation class="fade-in-bottom about delay-2">
        <h4 class="title">Sobre</h4>
        <p>
          Pensando em trazer o seu negócio para internet? Aqui você encontra as melhores soluções de comunicação digital.
          Crie e faça a gestão da jornada dos seus clientes em um lugar só.
        </p>
      </div>
      <div v-scrollanimation class="fade-in-bottom pages delay-3">
        <h4 class="title">Páginas</h4>
        <ul class="list square">
          <li class="item" v-for="page in pages" :key="page.id">
            <router-link class="link" :to="page.route">{{ page.label }}</router-link>
          </li>
        </ul>
      </div>
      <div v-scrollanimation class="fade-in-bottom contact delay-4">
        <h4 class="title">Contato</h4>
        <ul class="list square">
          <li class="item">
            <a 
              class="link"
              href="mailto:contato@datacoress.com"
              title="contato@datacoress.com">
              contato@datacoress.com
            </a>
          </li>
          <li class="item">
            <a class="link" href="tel:+5511969117556" title="+55 (11) 96911-7556">+55 (11) 96911-7556</a>
          </li>
          <!-- <li class="item">Av. Chrucri Zaidan, 1550 - 12º Andar - CJ. 1212,1213 - São Paulo/SP CEP. 04483-110</li> -->
        </ul>
      </div>
      <div v-scrollanimation class="social d_flex fade-in-bottom delay-5">
        <ul class="list d_flex">
          <li class="item" v-for="social in socialMedia" :key="social.id">
            <a class="link" :href="social.route" :aria-label="social.aria" target="_blank">
              <i :class="'icon ' + social.icon"></i>
            </a>
          </li>
        </ul>
        <router-link to="/" class="logo">
          <img class="img" src="../assets/img/logo-datacoress.png" alt="Logo Data Core Solutions" width="433" height="71">
        </router-link>
      </div>
    </div>
    <Effects :invert="true" />
  </footer>
</template>

<script>
import Effects from './common/Effects.vue'

export default {
  components: { Effects },
  name: 'Footer',
  data() {
    return {
      pages: [
        {
          id: 1,
          label: 'Soluções',
          route: '/solucoes'
        },
        {
          id: 2,
          label: 'Serviços',
          route: '/servicos'
        },
        {
          id: 3,
          label: 'Estudos de Caso',
          route: '/estudos-de-caso'
        },
        {
          id: 4,
          label: 'Sobre',
          route: '/sobre'
        },
        {
          id: 5,
          label: 'Contato',
          route: '/contato'
        },
        {
          id: 6,
          label: 'Privacidade',
          route: '/privacidade'
        },
      ],
      socialMedia: [
        {
          id: 1,
          icon: 'icon-facebook',
          route: 'https://www.facebook.com/datacoresolution',
          aria: "Facebook"
        },
        {
          id: 2,
          icon: 'icon-instagram',
          route: 'https://www.instagram.com/datacoresolutions/',
          aria: "Instagram"
        },
        {
          id: 3,
          icon: 'icon-linkedin',
          route: 'https://www.linkedin.com/company/data-core-solutions-servi%C3%A7os/',
          aria: "Linkedin"
        },
        {
          id: 4,
          icon: 'icon-youtube',
          route: 'https://www.youtube.com/channel/UC17E2UMI5IBKWnxacR-BcEA',
          aria: "Youtube"
        },
      ]
    }
  }
}
</script>


<style lang="scss" scoped>
  #footer {
    position: relative;
    padding-top: 100px;
    background-color: $DarkGrey;
    border-top: 5px solid $PrimaryColor;

    @include respond(md-up) {
      padding-top: 50px;
    }
    
    .container {
      flex-wrap: wrap;
      margin: 0 auto;
      text-align: center;
      @include respond(md-up) {
        align-items: flex-start;
        justify-content: space-between;
        text-align: left;
      }
      
      .title {
        margin-bottom: 10px;
        color: $TitleColor;
        font-size: 18px;
        text-transform: uppercase;
      }

      a {
        @include transition(color .2s linear);
        &:hover {
          color: $White;
        }
      }

      .about {
        @include respond(md-up) {
          width: 33.33%;
        }
      }

      .pages {
        width: 100%;
        margin: 20px 0;
        padding: 20px 0;
        @include respond(md-up) {
          width: 200px;
          margin: 0;
          padding: 0;
        }
        a {
          display: block;
        }
      }

      .contact {
        @include respond(md-up) {
          width: 33.33%;
        }
      }

      .social {
        flex-wrap: wrap;
        width: 100%;
        margin-top: 40px;
        padding-top: 40px;
        border-top: 1px solid #494949;

        @include respond(md-up) {
          justify-content: space-between;
        }

        .list {
          order: 1;
          width: 100%;
          margin-top: 20px;

          @include respond(md-up) {
            justify-content: flex-start;
            width: auto;
            margin-top: 0;
            order: 0;
          }

          a {
            display: block;
            margin: 0 5px;
            padding: 10px;
            background-color: #494949;
            line-height: normal;
            @include border-radius(5px);

            .icon {
              display: block;
              color: $TitleColor;
              font-size: 16px;
              @include transform(scale(.9));
              @include transition(all .2s ease-in);
            }
            &:hover {
              background-color: $PrimaryColor;
              .icon {
                color: $White;
                @include transform(scale(1));
              }
            }
          }
        }

        .logo {
          order: 0;
          display: block;
          max-width: 240px;

          @include respond(xl-up) {
            max-width: 280px;
          }
        }
      }
    }
  }
</style>
