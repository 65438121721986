<template>
  <section id="contact" class="section">
    <div class="container">      
      <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">Contato</h3>
      <div class="box d_flex">
        <div class="text">
          <h4 v-scrollanimation class="title">Vamos iniciar uma parceria de sucesso?</h4>
          <p v-scrollanimation class="delay-2 fade-in-bottom">Clique no botão ao lado e preencha seus dados que entraremos em contato para apresentar as melhores soluções para o melhor desempenho da sua empresa.</p>
        </div>
        <Button :route="'/contato'" :label="'Contatar'"/>
      </div>
    </div>
  </section>
</template>

<script>
import Button from './common/Button.vue'
export default {
  components: { Button },
  name: 'Contact',
}
</script>

<style lang="scss" scoped>
  #contact {
    @include respond(md-up) {}

    .container {
      padding-bottom: 0;

      .box {
        flex-wrap: wrap;
        padding: 30px;
        background: $GradientColorBottom;
        color: $White;
        @include border-radius(10px);
        @include box-shadow(0 10px 20px rgba(0,0,0,.2));

        @include respond(md-up) {
          flex-wrap: nowrap;
          padding: 25px 35px 20px;
          margin-bottom: 40px;
          text-align: left;

          .text {
            padding-right: 120px;
          }

          .button {
            margin-top: 0;
          }
        }

        .text {
          position: relative;
          z-index: 1;
          .title {
            font-size: 14px;
            text-transform: uppercase;
            @include respond(md-up) {
              font-size: 18px;
            }
          }
        }

        .button {
          position: relative;
          background-color: $DarkGrey;
          z-index: 1;

          &:hover {
            background-color: $White;
            color: $PrimaryColor;
          }
        }
      }
    }
  }
</style>
