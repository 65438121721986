<template>
  <div
    id="app"
    :class="`${
      classPath !== '' ? (hasOverlay ? 'page no-scroll' : 'page') : 'home'
    }`"
  >
    <Header />
    <router-view />
    <Chatbot />
    <Contact v-show="classPath !== 'contato'" />
    <Footer />
    <Splash :isLoading="isLoading" />
    <Cookies />
    <Overlay />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Chatbot from "./components/Chatbot.vue";
import Contact from "./components/Contact.vue";
import Footer from "./components/Footer.vue";
import Splash from "./components/Splash.vue";
import Cookies from "./components/Cookies.vue";
import Overlay from "./components/Overlay.vue";

export default {
  name: "DataCoreSolutions",
  components: {
    Header,
    Chatbot,
    Contact,
    Footer,
    Splash,
    Cookies,
    Overlay,
  },
  data() {
    return {
      isLoading: true,
      classPath: "",
    };
  },
  methods: {
    setClassPath() {
      this.classPath = this.$router.currentRoute.path.trim().replace("/", "");
    },
  },
  computed: {
    hasOverlay() {
      return this.$store.getters["overlayStore/getOverlay"];
    },
  },
  created() {
    this.setClassPath();

    // Remove Splash
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  },
  watch: {
    $route() {
      this.setClassPath();
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  overflow-x: hidden;
  &.no-scroll {
    overflow: hidden;
  }
}
</style>