<template>
  <ul class="list services cards d_flex">
    <li
      v-scrollanimation
      :class="'item card delay-1 fade-in-bottom' + (active ? ' active' : '')">
      <div class="d_flex icon">
        <span class="number">01</span>
        <i class="icon icon-planning"></i>
      </div>
      <h4 class="title">Fábrica de Software</h4>
      <div class="overlay">
        <p>
          Equipe qualificada com foco em desenvolvimento de sistemas que
          utilizam o que há de mais moderno em linguagens de programação.
        </p>
      </div>
    </li>
    <li
      v-scrollanimation
      :class="'item card fade-in-bottom delay-2' + (active ? ' active' : '')"
    >
      <div class="d_flex icon">
        <span class="number">02</span>
        <i class="icon icon-management"></i>
      </div>
      <h4 class="title">Consultor Outsourcing</h4>
      <div class="overlay">
        <p>
          Profissionais multidisciplinares e altamente capacitados para entender
          as necessidades e as ferramentas tecnológicas da empresa,
          proporcionando ganho e melhoria contínua de processos.
        </p>
      </div>
    </li>
    <li
      v-scrollanimation
      :class="'item card fade-in-bottom delay-3' + (active ? ' active' : '')"
    >
      <div class="d_flex icon">
        <span class="number">03</span>
        <img
          src="../../assets/svg/icon_suporte_tecnico_servicos_home.svg"
          alt="Suporte Técnico"
          class="item card img-icon-suporte-tecnico"
          width="110"
          height="59"
        />
      </div>
      <h4 class="title">Suporte Especializado 24/7</h4>
      <div class="overlay">
        <p>
          Atendimento especializado dos níveis de suporte 1, 2 e 3, que garante
          estabilidade e cobertura completa na resolução de problemas.
        </p>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: "ServiceCard",
  data() {
    return {
      active: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.list.cards {
  cursor: default;
  @include respond(sm2-up) {
    max-width: 640px;
    margin: -10px auto;
  }
  @include respond(lg-up) {
    max-width: none;
    margin: -20px;
  }
  .item.card {
    width: calc(100% - 20px);
    padding: 40px 30px;

    @include respond(sm2-up) {
      width: calc(50% - 20px);
      max-width: 300px;
    }
    @include respond(lg-up) {
      width: calc(25% - 40px);
      max-width: none;
      height: 250px;
      margin: 20px;
    }

    .icon {
      justify-content: space-between;
      .number {
        font-size: 32px;
        font-weight: 900;
      }
      i.icon {
        display: block;
        color: $PrimaryColor;
        font-size: 60px;
        @include respond(md-up) {
          font-size: 80px;
        }
      }
    }

    .title {
      margin-top: 20px;
      text-align: left;
    }
  }
}
</style>
