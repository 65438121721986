<template>
  <main id="caseStudy">
    <header class="header">
      <div class="container">
        <h1 v-scrollanimation class="title delay-1 fade-in-bottom">
          Estudos de Caso
        </h1>
        <p v-scrollanimation class="delay-2 fade-in-bottom">
          Conheça como empresas estão revolucionando a <br>experiência do cliente com soluções da Data Core Solutions.
        </p>
      </div>
    </header>
    <CaseSection/>
  </main>
</template>

<script>
import CaseSection from '../components/caseStudy/CaseSection.vue'

export default {
  name: "CaseStudy",
  components: {
    CaseSection,
  },
};
</script> 

<style lang="scss" scoped>
#caseStudy {
  .success_story,
  .why {
    background-color: $DarkGrey;

    .text {
      text-align: center;
      p {
        @include respond(md-up) {
          flex-basis: 50%;
          &:first-child {
            padding-right: 30px;
          }
          &:last-child {
            padding-left: 30px;
          }
        }
      }
    }
  }
  
  .header {
    background-image: url("../assets/img/estudos_de_caso/header_estudos_de_caso.jpg") !important;
  }
}
</style>