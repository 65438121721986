<template>
  <div v-show="!isMobile" id="effects" :class="invert ? 'invert' : 'normal'">
    <img
      v-scrollanimation
      class="square two slide-top delay-5"
      src="../../assets/svg/effects/squares_grey.svg" alt="efeito quadrado">
    <img
      v-scrollanimation
      :class="'hexagon delay-5 ' + (invert ? 'slide-right-hex' : 'slide-left-hex' )"
      src="../../assets/svg/effects/hexagon.svg" alt="animação hexagonal">
  </div>
</template>
<script>
export default {
  name: 'Effects',
  props: {
    invert: {
      type: Boolean,
      required: true,
    }
  }
};
</script>

<style lang="scss" scoped>
  #effects {
    position: absolute;
    top: 0;
    width: calc(100% - 60px);
    margin: 0 30px;
    height: 100%;
    z-index: 0;

    @include respond(sm2-up) {
      width: calc(100% - 120px);
      margin: 0 60px;
    }
    @include respond(lg-up) {
      width: 100%;
      margin: 0;
    }

    .square {
      position: absolute;
      width: 30px;
      animation-duration: 2s !important;
      
      @include respond(md-up) {
        width: 40px;
      }
    }

    .hexagon {
      position: absolute;
      width: 120px;
      animation-duration: 2s !important;
      
      @include respond(md-up) {
        width: 200px;
      }
    }

    &.normal {
      .square {
        top: 20%;
        right: 0;

        @include respond(lg-up) {
          top: 15%;
          right: 5%;
        }
      }
      
      .hexagon {
        left: -60px;
        bottom: 20%;

        @include respond(md-up) {
          left: -100px;
          width: 200px;
        }        
        @include respond(lg-up) {
          bottom: 15%;
        }
      }
    }

    &.invert {
      .square {
        left: 0;
        bottom: 20%;
        @include respond(lg-up) {
          left: 5%;
          bottom: 15%;
        }
      }
      
      .hexagon {
        top: 20%;
        right: -60px;

        @include respond(md-up) {
          right: -100px;
          width: 200px;
        }        
        @include respond(lg-up) {
          top: 15%;
        }
      }
    }
  }
</style>
