const modalStore = {
  namespaced: true,
  state: {
    modalIsActive: false,
  },
  mutations: {
    setModalVisibility: (state, value) => {
      state.modalIsActive = value;
    },
  },
  getters: {
    getModalIsActive: (state) => state.modalIsActive,
  },
};

export default modalStore;
