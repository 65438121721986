const menuStore = {
  namespaced: true,
  state: {
    menuIsActive: false,
  },
  mutations: {
    setMenuVisibility: (state, value) => {
      state.menuIsActive = value;
    },
  },
  getters: {
    getMenuIsVisible: (state) => state.menuIsActive,
  },
};

export default menuStore;
