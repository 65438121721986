<template>
  <main class="chatbot">
    <header class="header">
      <title>ChatBot | Data Core Solutions</title>
      <meta
        name="description"
        content="Gerencie as trocas de mensagens, simulando uma
            conversa humana com linguagem natural, por meio de aplicativos de
            mensagens, sites e outras plataformas digitais.">
      <div class="container">
        <h1 v-scrollanimation class="title delay-1 fade-in-bottom">Chatbot</h1>
        <p v-scrollanimation class="delay-2 fade-in-bottom">
          Atendimento automatizado do seu jeito.
        </p>
      </div>
    </header>
    <section class="section what">
      <div class="container">
        <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">
          O que é o CHATBOT?
        </h3>
        <div class="content d_flex">
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            É um software que gerencia as trocas de mensagens, simulando uma
            conversa humana com linguagem natural, por meio de aplicativos de
            mensagens, sites e outras plataformas digitais. São sistemas que
            usam interface conversacional para entregar um produto, serviço ou
            experiência e reduzir custos para as empresas. Os chatbots podem ser
            chamados por outros nomes como assistentes virtuais, agentes
            virtuais ou apenas bot. O conceito da palavra chatbot é a junção das
            palavras “chat”, que em inglês significa conversa e “bot” que em
            inglês significa robô.
          </p>
        </div>
      </div>
    </section>
    <section class="section images">
      <ul class="list d_flex">
        <li class="item">
          <img
            v-scrollanimation
            class="delay-1 scale-in-center"
            src="../../assets/img/produtos/chatbot_apps.jpg"
            alt="Smartphone contendo vários apps de comunicação"
          />
        </li>
        <li class="item">
          <img
            v-scrollanimation
            class="delay-2 scale-in-center"
            src="../../assets/img/produtos/chatbot_slack.jpg"
            alt="Conteúdo de métricas exibido dentro do Slack"
          />
        </li>
        <li class="item">
          <img
            v-scrollanimation
            class="delay-3 scale-in-center"
            src="../../assets/img/produtos/chatbot_whatsapp.jpg"
            alt="Smartphone com o WhatsApp aberto"
          />
        </li>
      </ul>
    </section>
    <section class="section features">
      <div class="container d_flex">
        <div class="demo">
          <i
            v-scrollanimation
            class="icon icon-chatbot delay-1 fade-in-bottom"
          ></i>
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            Qualidade no atendimento aos clientes, agilidade nas respostas aos
            usuários e redução de custos operacionais. Tudo isso em uma
            ferramenta que trabalha por sua empresa, para oferecer melhores
            resultados.
          </p>
          <!-- <Button
            v-show="!isMobile"
            :addClass="'delay-3'"
            :label="'Ver demonstração'" 
            @clickButton="showDemo"/> -->
        </div>
        <ul class="list">
          <li v-scrollanimation class="item delay-1 fade-in-bottom">
            <h4 class="title">Agilidade</h4>
            <p>
              Elimine o tempo de espera do seu cliente! Todos os atendimentos
              comuns e corriqueiros, chamados de nível 1, que demandam esforço
              repetitivo, podem ser atribuídos ao chatbot, encaminhando para os
              atendentes, somente os casos mais complexos, chamados nível 2.
            </p>
          </li>
          <li v-scrollanimation class="item delay-2 fade-in-bottom">
            <h4 class="title">Alcance e Qualidade</h4>
            <p>
              Tenha melhor alcance e mantenha a qualidade do atendimento. O
              chatbot e uma excelente alternativa tecnológica para empresas que
              precisam escalar o atendimento, mantendo a qualidade e satisfação
              dos clientes.
            </p>
          </li>
          <li v-scrollanimation class="item delay-3 fade-in-bottom">
            <h4 class="title">Suporte e Redução de Custos</h4>
            <p>
              Reduza custos e ofereça suporte independentemente do local e
              horário. Comunicação imediata de qualidade, com o benefício de
              redução de custos operacionais. Os chatbots podem responder até
              80% das perguntas comuns, 24 horas por dia, 7 dias na semana e 365
              dias no ano. Variando o segmento de atuação, algumas empresas
              conseguem redução nos custos em até 83%.
            </p>
          </li>
        </ul>
      </div>
    </section>

    <!-- <ModalIframe 
      :src="'https://www.youtube.com/embed/6Cp6mKbRTQY'"
      :title="'YouTube video player'"
      :addClass="demoActive ? 'active' : ''"
    /> -->
  </main>
</template>
<script>
// import Button from '../../components/common/Button.vue';
// import ModalIframe from '../../components/ModalIframe.vue';
export default {
  // components: { Button, ModalIframe },
  name: "Chatbot",
  methods: {
    showDemo() {
      this.$store.commit("overlayStore/setOverlay", true);
      this.$store.commit("modalStore/setModalVisibility", true);
    },
  },
  computed: {
    demoActive() {
      return this.$store.getters["modalStore/getModalIsActive"];
    },
  },
};
</script>