<template>
  <ul class="list cards">
    <li 
      v-for="info in informations" :key="info.id"
      v-scrollanimation
      :class="'item card fade-in-bottom delay-' + (info.id)"
      @click="toggleActive($event)">
      <div class="header d_flex">
        <span class="number">{{ `0${info.id}` }}</span>
        <h4 class="title">{{ info.title }}</h4>
        <i class="icon icon-chevron-down"></i>
      </div>
      <div class="text">
        <p>{{ info.text }}</p>
      </div>
    </li>
  </ul>
</template>

<script>

export default {
  name: 'ComunicationCard',
  props: {
    data: {
      id: Number,
      title: String,
      text: String,
      route: String,
    },
  },
  methods: {
    toggleActive(e) {
      e.currentTarget.classList.toggle('active');
    },
  },
  data() {
    return {
      informations: [
        {
          id: 1,
          title: 'Comunicação Massiva',
          text: 'A solução que entrega grandes volumes de comunicação massiva em uma velocidade extraordinária!',
          route: '/',
        },
        {
          id: 2,
          title: 'Comunicação On Demand',
          text: 'A solução permite criar comunicações personalizadas e responsivas por e-mail e SMS de forma rápida e fácil.',
          route: '/',
        },
        {
          id: 3,
          title: 'Engajamento',
          text: 'A solução que conversa exclusivamente com cada cliente proporcionando uma experiência inesquecível a cada usuário.',
          route: '/',
        },
        {
          id: 4,
          title: 'Auto Atendimento',
          text: 'O poder de resolver suas próprias solicitações de forma rápida e sem precisar da ajuda de terceiros.',
          route: '/',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
  .list.cards {
    width: 100%;

    @include respond(md-up) {
      width: 50%;
      padding-left: 80px;
    }

    .item.card {
      width: calc(100% - 20px) !important;
      max-width: 450px;
      margin: 10px auto !important;

      .header {
        justify-content: space-between;

        .number {
          font-size: 18px;
          font-weight: 900;
          text-align: left;

          @include respond(sm2-up) {
            font-size: 24px;
          }
          @include respond(md-up) {
            font-size: 32px;
          }
        }

        .title {
          width: calc(100% - 20px);
          margin: 0 10px;
          text-align: left;

          @include respond(md-up) {
            width: calc(100% - 40px);
            margin: 0 10px 0 30px;
          }
        }

        i.icon {
          color: $TitleColor;
          font-size: 14px;

          @include transition(all .3s ease);

          @include respond(sm2-up) {
            font-size: 18px;
          }
          @include respond(md-up) {
            font-size: 24px;
          }
        }
      }

      .text {
        max-height: 0;
        color: $White;
        overflow: hidden;
        @include transition(all .3s ease);
        p {
          margin: 20px 0 0;
          text-align: left;
        }
      }

      &:hover {
        .header {
          .number {
            color: $TitleColor;
          }
        }
      }

      &.active {
        .header {
          .number {
            color: $PrimaryColor;
          }

          .title {
            color: $White;
          }

          i.icon {
            color: $PrimaryColor;
            @include transform(rotate(180deg));
          }
        }

        .text {
          max-height: 200px;
        }
      }
    }
  }
</style>