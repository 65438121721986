<template>
  <main id="about">
    <header class="header">
      <div class="container">
        <h1 v-scrollanimation class="title delay-1 fade-in-bottom">
          Nós tornamos o seu negócio em digital
        </h1>
        <p v-scrollanimation class="delay-2 fade-in-bottom">
          As melhores soluções de comunicação digital.
        </p>
      </div>
    </header>
    <section class="section meet_company">
      <div class="container">
        <h3 v-scrollanimation class="title_section delay-1 fade-in-bottom">
          Conheça a Data Core Solutions
        </h3>
        <div class="text d_flex">
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            Há 5 anos no mercado, a <strong>Data Core Solutions</strong> é uma empresa de tecnologia com foco no CCM, CX e outras soluções de integração de sistemas em plataformas Java, .Net, entre outras. Nosso objetivo é facilitar a vida das pessoas por meio do uso da tecnologia de maneira simples e com a maior abrangência possível e ajudar nossos clientes a tirar o melhor proveito dos seus sistemas.
          </p>
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            Contamos com um grupo de consultores experientes e altamente qualificados nas principais ferramentas do mercado, que está exigindo cada vez mais transformações nos processos de trabalho das empresas e de comunicação com os clientes.
          </p>
        </div>
      </div>
    </section>
    <section class="section company history">
      <div class="container">
        <h3 v-scrollanimation class="title_section delay-1 fade-in-bottom">
          História
        </h3>
        <div class="text d_flex">
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            A Data Core Solutions surgiu em 2017, com a trajetória de sucesso profissional do CEO Manuel Alejandro Reyes Bustos.
          </p>  
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            Manuel nasceu no México e trabalhou em uma empresa de consultoria como Gerente de Desenvolvimento, cargo que demandou viagens para diversos países.
          </p>
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            Em 2009, veio ao Brasil para cuidar de projetos de duas grandes empresas, uma no Rio de Janeiro e outra em São Paulo. Por conta da demanda, optaram por manter-se em SP, onde seria instalada a sede brasileira da empresa de consultoria que trabalhava.
          </p>
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            Com a empresa fixada no Brasil, assumiu a área de liderança técnica, tocando projetos de implementação de sistemas. Identificaram desafios devido à grande demanda de trabalho e escassa mão de obra local. Um deles foi o complexo sistema tributário nacional, motivo pelo qual a empresa encerrou as atividades por aqui. 
          </p>
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            Recebeu uma nova proposta para continuar atuando no Brasil em outra empresa. Trabalhou em uma gigante do setor de tecnologia e entre estudo (especialização), muito trabalho e foco, obteve excelentes resultados profissionais, até fundar a sua própria companhia. 
          </p>
        </div>
        <div class="text d_flex">
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            Possui um time super comprometido, que atua em grandes clientes como: Vivo, Santander e Bayer.
          </p>  
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            A Data Core é uma empresa que reconhece seus funcionários e que fornece um ambiente colaborativo agradável, que valoriza as pessoas da equipe com respeito e incentiva o crescimento.
          </p>
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            No Brasil, acompanha o Customer Experience e utiliza as tecnologias mais modernas do mercado, destacando-se assim pela inovação e qualidade oferecida nas soluções.
          </p>
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            Incorpora uma filosofia de proximidade com o cliente em todos os projetos, efetivando assim o desenvolvimento de soluções que se adequam a necessidade de cada um dos clientes.
          </p>
        </div>
      </div>
    </section>
    <section class="section company">
      <div class="container">
        <div class="text d_flex">
          <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">
            Missão
          </h3>
          <p v-scrollanimation class="delay-1 fade-in-bottom">
            Nossa missão é ser um facilitador entre as empresas e o uso da
            tecnologia. Queremos ser o principal tech advisor (consultor
            técnico) de nossos clientes, estabelecendo vínculos de confiança e
            passando todo conhecimento necessário para que tirem o máximo
            proveito de seus sistemas.
          </p>
          </div>
           <div class="text d_flex">
            <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">
            Visão
          </h3>
          <p v-scrollanimation class="delay-1 fade-in-bottom">
            Ser reconhecida como a maior referência do uso de tecnologias para
            engajar marcas e melhorar as experiências de comunicação com seus
            clientes. A Data Core Solutions deseja impactar positivamente a
            sociedade e o bem-estar dos colaboradores das empresas por meio da
            tecnologia.
          </p>
          </div>
          <div class="text d_flex">
            <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">
            Valores
          </h3>
          <p v-scrollanimation class="listSquare delay-1 fade-in-bottom">
            <ul class="list square">
              <li class="item">
                Respeito
              </li>
              <li class="item">
                Confiança
              </li>
              <li class="item">
                Honestidade
              </li>
              <li class="item">
                Dedicação e compromisso
              </li>
              <li class="item">
                Paixão por tecnologia
              </li>
              <li class="item">
                Integridade
              </li>
            </ul>
          </p>
          </div>
      </div>
    </section>

    <section class="section clients">
      <div v-scrollanimation class="container delay-1 fade-in-bottom">
        <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">
          Nossos Clientes
        </h3>
        <ul class="list d_flex"></ul>
        <splide :options="options">
          <splide-slide v-for="client in clients" :key="client.id">
            <!-- <AboutClients :data="client"/> -->
            <img
              :class="'img' + client.id"
              :src="require(`../assets/img/clientes/${client.img}.png`)"
              :alt="client.imgAlt"
            />
          </splide-slide>
        </splide>
      </div>
    </section>
    <!-- <AboutGallery class="section gallery"/> -->
  </main>
</template>

<script>
// import AboutReasons from '../components/about/AboutReasons.vue';
// import AboutClients from '../components/about/AboutClients.vue';
// import AboutGallery from '../components/about/AboutGallery.vue';

export default {
  name: "About",
  components: {
    // AboutReasons,
    // AboutClients,
    // AboutGallery,
  },
  data() {
    return {
      // reasons: [
      //   {
      //     id: 1,
      //     icon: "icon-target",
      //     title: "Missão",
      //     text: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
      //   },
      //   {
      //     id: 2,
      //     icon: "icon-trust",
      //     title: "Confiança",
      //     text: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
      //   },
      //   {
      //     id: 3,
      //     icon: "icon-world",
      //     title: "Sustentabilidade",
      //     text: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
      //   },
      // ],

      // Configuração do Slide
      options: {
        autoplay: true,
        pauseOnHover: false,
        rewind: true,
        perMove: 1,
        perPage: 1,
        speed: 600,
        arrows: true,
        gap: 0,
        keyboard: false,
      },

      clients: [
        {
          id: 1,
          img: "santander",
          imgAlt: "Satander",
        },
        {
          id: 2,
          img: "claro",
          imgAlt: "Claro",
        },
        {
          id: 3,
          img: "nextel",
          imgAlt: "Nextel",
        },
        {
          id: 4,
          img: "telefonica",
          imgAlt: "Telefônica",
        },
        {
          id: 5,
          img: "bayer",
          imgAlt: "Bayer",
        },
        {
          id: 6,
          img: "galois",
          imgAlt: "Galois",
        },
        {
          id: 7,
          img: "pitney-bowes-brasil",
          imgAlt: "Pitney Bowes Brasil",
        },
        {
          id: 8,
          img: "precisely",
          imgAlt: "Precisely",
        },
      ],
    };
  },
  methods: {
    checkClientsDisplay() {
      const isMobile = this.$data.isMobile;
      this.options.perPage = isMobile ? 2 : 4;
    },
  },
  async created() {
    this.checkClientsDisplay();
  },
};
</script> 
<style lang="scss" scoped>
#about {
  .meet_company,
  .why {
    background-color: $DarkGrey;

    .text {
      align-items: flex-start;
      flex-wrap: wrap;
      p {
        @include respond(md-up) {
          flex-basis: 50%;
          &:first-child {
            padding-right: 30px;
          }
          &:last-child {
            padding-left: 30px;
          }
        }
      }
    }
  }

  .reasons {
    background-color: $DarkGreySecondary;
    .list {
      flex-wrap: wrap;
    }
  }

  .clients {
    background-color: $DarkGreySecondary;

    .container {
      @include respond(md-up) {
        padding: 80px 30px;
      }
    }
  }

  .gallery {
    background-color: $DarkGrey;
  }

  .company {
    background-color: $DarkGrey;

    @include respond(sm1-up) {
      margin: 0 auto;
    }

    .text {
      float: left;
      margin-right: 10px;
      width: calc(33.3% - 10px);
      padding: 10px;
      flex-wrap: wrap;
      margin-top: -16px;

      @include respond(sm1-up) {
        width: auto;
        margin-right: 0;
        flex-direction: column;
        float: none;
      }

      h3 {
        text-align: center;
        margin-bottom: 20px;
      }

      p {
        @include respond(md-up) {
          &:first-child {
            padding-right: 0px !important;
          }

          &:last-child {
            padding-left: 0px !important;
          }
        }
      }
    }

    ul,
    .listSquare {
      text-align: center;
      display: block;
      padding-right: 5px;
    }

    &.history {
      background-color: $DarkGreySecondary;

      .text {
        width: calc(50% - 10px);
        margin-bottom: 45px;

        @include respond(sm1-up) {
          width: auto;
          margin-right: 0;
          flex-direction: column;
          float: none;
        }
      }
    }
  }
}
</style>