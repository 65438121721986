<template>
  <div id="menu" :class="isMobile ? 'mobile' : 'desktop'">
    <nav>
      <span v-if="isMobile" class="icon show_menu" @click="showMenu()">
        <i class="icon icon-menu"></i>
      </span>
      <div class="content d_flex" :class="menuActive ? 'active' : ''">
        <span v-if="isMobile" class="icon hide_menu" :class="menuActive ? 'active' : ''" @click="hideMenu()">
          <i class="icon icon-arrow-left"></i>
        </span>
        <ul class="categories" :class="isMobile ? '' : ' d_flex'">
          <li class="item"
            v-for="route in routes"
            :key="route.path">
            <router-link
              class="link"
              v-if="route.path !== '/produtos' && route.menu"
              :to="route.path"
              @click.native="hideMenu()">
              {{ route.name }}
            </router-link>

            <span
              v-else-if="route.menu"
              :class="`no-link ${submenuIsVisible ? ' active' : ''}`"
              @click="toggleSubmenu()">
              {{ route.name }}
            </span>
            <ul class="children" v-if="route.children">
              <li class="item" 
                v-for="childRoute in route.children"
                :key="childRoute.path">
                <router-link
                  :to="`${route.path}/${childRoute.path}`"
                  @click.native="toggleSubmenu(); hideMenu()">
                  {{ childRoute.name }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>

export default {
  name: 'Menu',
  props: {
    routes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      submenuIsVisible: false,
    };
  },
  methods: {
    showMenu() {
      this.$store.commit('overlayStore/setOverlay', true);
      this.$store.commit('menuStore/setMenuVisibility', true);
    },
    hideMenu() {
      this.$store.commit('menuStore/setMenuVisibility', false);
      this.$store.commit('overlayStore/setOverlay', false);
    },
    toggleSubmenu() {
      this.submenuIsVisible = !this.submenuIsVisible;
    },
  },
  computed: {
    menuActive() {
      return this.$store.getters['menuStore/getMenuIsVisible'];
    },
  },
}
</script>

<style lang="scss" scoped>
#menu {
  .no-link {
    &+.children {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      @include transition(all .3s ease);

      .item {
        a {
          display: block;
          text-align: center;
          text-transform: none;
        }
      }
    }
  }

  &.mobile {
    i.icon {
      display: block;
      color: $TextColor;
      font-size: 24px;
    }

    .show_menu {
      display: block;
    }

    .hide_menu {
      position: absolute;
      left: 30px;
      top: 15px;
      opacity: 0;
      pointer-events: none;
      @include transform(scale(.5));
      @include transition(.3s all .3s linear);
      z-index: 5;
      &.active {
        opacity: 1;
        pointer-events: auto;
        @include transform(scale(1));
      }
    }
    .content {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      max-width: 400px;
      height: 100vh;
      padding: 60px 30px;
      background-color: $Black;
      opacity: 0;
      @include transform(translateX(-100%));
      @include transition(all .3s ease);
      .item {
        margin: 10px 0;
        opacity: 0;
        @include transition(all .3s ease);
        .link, .no-link {
          display: block;
          padding: 10px 15px;
          color: $TextColor;
          font-weight: bold;
          text-align: center;
          text-transform: uppercase;
          letter-spacing: .1em;
          
          @include transition(all .3s ease);
        }

        .link {
          &:hover {
            color: $White;
            letter-spacing: .2em;
          }
        }
        
        .no-link {
          &.active {
            color: $PrimaryColor;
            letter-spacing: .2em;
            &+.children {
              max-height: 200px;
              opacity: 1;
            }
          }
        }
      }
      &.active {
        opacity: 1;
        @include transform(translateX(0%));
        .item {
          animation-name: fade-in-bottom;
          animation-duration: .5s;
          animation-fill-mode: forwards;
          &:nth-child(1) {
            animation-delay: .5s;
          }
          &:nth-child(2) {
            animation-delay: .7s;
          }
          &:nth-child(3) {
            animation-delay: .9s;
          }
          &:nth-child(4) {
            animation-delay: 1.1s;
          }
          &:nth-child(5) {
            animation-delay: 1.3s;
          }
          &:nth-child(6) {
            animation-delay: 1.5s;
          }
        }
      }
    }
  }
  &.desktop {
    .content {
      .item {
        margin: 0 14px;
        opacity: 0;
        @include transition(all .3s ease);
        animation-name: fade-in-bottom;
        animation-duration: .5s;
        animation-fill-mode: forwards;

        @include respond(xl-up) {
          margin: 0 20px;
        }

        &:nth-child(1) {
          animation-delay: .5s;
        }
        &:nth-child(2) {
          animation-delay: .7s;
        }
        &:nth-child(3) {
          animation-delay: .9s;
        }
        &:nth-child(4) {
          animation-delay: 1.1s;
        }
        &:nth-child(5) {
          animation-delay: 1.3s;
        }
        &:nth-child(6) {
          animation-delay: 1.5s;
        }
        &:nth-child(n+7) {
          animation-delay: 1.7s;
          margin: 0;
        }
        
        .link, .no-link {
          display: block;
          padding: 18px 0;
          color: $TextColor;
          font-size: 14.5px;
          font-weight: bold;
          text-transform: uppercase;    
          @include transition(all .3s ease);
        }

        .link,
        .no-link {
          &:hover {
            color: $White;
          }
        }

        .no-link {
          position: relative;
          cursor: pointer;

          &+.children {
            position: absolute;
            left: calc(50% - 100px);
            top: 45px;
            width: 210px;
            padding: 6px 20px;
            background-color: $DarkGrey;
            border-radius: 4px;
            
            .item {
              margin: 10px 0;

              a {
                @include transition(all .3s ease);

                &:hover {
                  color: $White;
                  letter-spacing: .05em;
                }
              }
            }
          }
        }
        &:hover {
          .no-link {
            color: $PrimaryColor;
            letter-spacing: .05em;
            &+.children {
              max-height: 300px;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
</style>
