<template>
  <div class="container d_flex">
    <ul class="list actions cards d_flex">
      <li
        v-scrollanimation
        :class="'item card delay-1 fade-in-bottom ' + tabs[0].className"
        @click="tabChange(tabs[0].id)"
      >
        <img
          src="../../assets/svg/icon_autoatendimento_home.svg"
          alt="Autoatendimento"
          class="icon icon-files flip-vertical-left"
          width="100"
          height="100"
        />
      </li>
      <li
        v-scrollanimation
        :class="'item card delay-2 fade-in-bottom ' + tabs[1].className"
        @click="tabChange(tabs[1].id)"
      >
        <img
          src="../../assets/svg/icon_personalizacao_dados_comunicacao.svg"
          alt="Personalização"
          class="icon icon-mail_line flip-vertical-left"
          width="100"
          height="100"
        />
      </li>
      <li
        v-scrollanimation
        :class="'item card delay-3 fade-in-bottom ' + tabs[2].className"
        @click="tabChange(tabs[2].id)"
      >
        <img
          src="../../assets/svg/icon_omnichannel_integracao.svg"
          alt="Comceito Omnichannel"
          class="icon icon-sms flip-vertical-left"
          width="100"
          height="100"
        />
      </li>
      <li
        v-scrollanimation
        :class="'item card delay-4 fade-in-bottom ' + tabs[3].className"
        @click="tabChange(tabs[3].id)"
      >
        <img
          src="../../assets/svg/icon_ccm_home_gerenciamentos.svg"
          alt="Gerenciamento de Comunicaçõe - CCM"
          class="icon icon-chat flip-vertical-left"
          width="100"
          height="100"
        />
      </li>
    </ul>
    <div class="content">
      <i v-if="!isMobile" class="icon icon-chevron-right"></i>
      <div
        v-for="content in tabs"
        :key="content.id"
        :id="content.idName"
        :class="'item ' + content.className"
      >
        <h4 class="title">{{ content.title }}</h4>
        <p v-html="content.text"></p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NumbersTab",
  data() {
    return {
      tabs: [
        {
          id: 1,
          idName: "files",
          className: "active",
          title: "Autoatendimento",
          text: "Entrega de qualidade no autoatendimento do cliente.",
        },
        {
          id: 2,
          idName: "personalization",
          className: "",
          title: "Personalização",
          text: "Atualmente, com tanta informação que é compartilhada, as pessoas prestam atenção somente no que é relevante para elas.",
        },
        {
          id: 3,
          idName: "omnichannel concept",
          className: "",
          title: "Conceito Omnichannel",
          text: "Integração de diversos canais para melhor experiência do usuário.",
        },
        {
          id: 4,
          idName: "Communication Manager",
          className: "",
          title: "Gerenciador de Comunicações - CCM",
          text: "Gerencie todas as comunicações da sua empresa em um só lugar.",
        },
      ],
    };
  },
  methods: {
    tabChange(tabIndex) {
      this.tabs = this.tabs.map((e) => ({
        ...e,
        className: e.id === tabIndex ? "active" : "",
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  flex-wrap: wrap;

  @include respond(md-up) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .actions {
    flex-wrap: wrap;
    margin-bottom: 20px;

    .item {
      cursor: pointer;
      width: auto !important;

      .icon {
        display: block;
        width: 80px;
        filter: grayscale(100%);
        @include respond(md-up) {
          width: 100px;
        }
      }

      &.active {
        .icon {
          filter: grayscale(0%);
        }
      }
    }

    @include respond(md-up) {
      width: 40%;
    }
  }

  .content {
    position: relative;
    max-width: 700px;

    @include respond(md-up) {
      width: 60%;
      padding-left: 200px;
      text-align: left;

      .icon {
        position: absolute;
        left: 70px;
        top: calc(50% - 30px);
        color: $TitleColor;
        font-size: 40px;
      }
    }
    .item {
      height: 0;
      opacity: 0;
      overflow: hidden;

      .title {
        color: $TitleColor;
        font-size: 18px;
        text-transform: uppercase;
      }

      &.active {
        height: auto;
        opacity: 1;
        -webkit-animation: fade-in-bottom 0.8s
          cubic-bezier(0.39, 0.575, 0.565, 1) both;
        animation: fade-in-bottom 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      }
    }
  }
}
</style>