<script>
import ComunicationCard from './ComunicationCard.vue';
// import Button from '../common/Button.vue';
import Effects from '../common/Effects.vue';
export default {
  name: 'ComunicationSection',
  components: {
    ComunicationCard,
    // Button,
    Effects,
  },
};
</script>

<style lang="scss" scoped>
#comunication {
  background-color: $DarkGreySecondary;
  
  .container {
    flex-wrap: wrap;

    @include respond(md-up) {
      flex-wrap: nowrap;
    }

    .text {
      max-width: 700px;
      margin: 0 auto 40px;

      @include respond(md-up) {
        width: 50%;
        text-align: left;
      }

      p {
        margin: 0;
      }
    }
  }
}
</style>