const animatedScroll = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      if( entry.isIntersecting) {
        entry.target.classList.add('enter');
        animatedScroll.unobserve(entry.target);
      }
    })
  }
);

export default {
  bind(e) {
    e.classList.add('before-enter');
    animatedScroll.observe(e);
  }
}