<template>
  <section id="banner">
    <div class="carousel">
      <splide v-if="enableSlide" :options="options">
        <splide-slide v-for="banner in banners" :key="banner.id">
          <BannerSlide :data="banner" />
        </splide-slide>
      </splide>
    </div>
  </section>
</template>

<script>
import BannerSlide from "./BannerSlide.vue";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
export default {
  name: "Banner",
  components: {
    BannerSlide,
    Splide,
    SplideSlide,
  },
  data() {
    return {
      enableSlide: true,

      // Configuração do Slide
      options: {
        autoplay: true,
        interval: 7000,
        pauseOnHover: false,
        rewind: true,
        type: "fade",
        perPage: 1,
        speed: 600,
        arrows: false,
        gap: 0,
      },

      // Informações dos Banners
      banners: [
        {
          id: 1,
          img: "video_interativo_bg.webp",
          imgAlt: "Wallpaper Vídeo iterativo",
          title: "A experiência de autoatendimento que <span><br>todo cliente gostaria de ter</br></span>",
          text: "Ofereça aos clientes uma experiência inovadora e surpreendente \natravés de uma experiência única de CX! Uma nova realidade de \natendimento, mais humanizada, eficiente e inclusiva.",
          button: "Conhecer",
          route: "/produtos/video-interativo",
        },
        {
          id: 2,
          img: "hub_solucoes.webp",
          imgAlt: "Wallpaper sobre hub de soluções",
          title: "Conheça nosso <span><br>hub de soluções</br></span>",
          text: "Grandes empresas, como Vivo e Santander, confiam na Data Core Solutions para comunicações ágeis e dinâmicas com os clientes.",
          button: "Saber mais",
          route: "/solucoes",
        },
        {
          id: 3,
          img: "amostras_medicamentos_bg.webp",
          imgAlt: "Wallpaper amostras de medicamentos",
          title:"Entregue amostras de maneira mais escalável e <span>eficiente com um representante 100% digital</span>",
          text: "Aumente o número de amostras de medicamentos distribuídas por sua empresa por meio de um representante digital, oferecendo uma experiência única e inovadora aos médicos.",
          button: "Saber Mais",
          route: "/produtos/video-interativo",
        },
        // {
        //   id: 4,
        //   img: "faturas_bg.png",
        //   imgAlt: "Wallpaper sobre criação de faturas",
        //   title: "Crie todas as faturas da empresa <span>em uma única ferramenta</span>",
        //   text: "Ferramenta de alta performance utilizada por gigantes do mercado, como Vivo e Santander, para criação de faturas e boletos em segundos.",
        //   button: "Saber Mais",
        //   route: "/produtos/formatador-unico",
        // },
        {
          id: 4,
          img: "representantes-farmaceuticos-digitais-o-futuro-da-industria-baixe-o-ebook.webp",
          imgAlt: "Download eBook - Representantes Farmacêuticos Digitais - O Futuro da indústria",
          title: "Representantes Digitais<br><span>O Futuro da Indústria Farmacêutica</span>",
          text: "Baixe nosso e-book e saiba como a tecnologia está transformando a relação entre médicos e farmacêuticas.",
          button: "Baixar Agora",
          route: "/ebook/representante_digital/",
        },
        {
          id: 5,
          img: "repositorio_unico_bg.webp",
          imgAlt: "Wallpaper repositório único",
          title: "Centralize o armazenamento de documentos e <span>disponibilize aos clientes com conceito omnichannel</span>",
          text: "Melhore a experiência dos seus clientes com um repositório único de alta performance que comprime os documentos em até 90% e os disponibiliza em todos os canais da empresa.",
          button: "Conhecer",
          route: "/produtos/repositorio-unico",
        },
        {
          id: 6,
          img: "documentos_digitais_bg.webp",
          imgAlt: "Wallpaper documentos digitais",
          title:"Crie milhões de documentos digitais <span>em segundos</span>",
          text: "Um hub de criação, centralização e personalização dos documentos digitais da sua empresa, como templates de e-mails, SMS e PDF em um só lugar.",
          button: "Conhecer",
          route: "/produtos/formatador-unico",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#banner {
  position: relative;
  background-color: $Black;
  z-index: 1;

  .carousel,
  .splide {
    height: 100vh;
    overflow: hidden;
  }
}
</style>