<template>
  <section id="numbers" class="section">
    <div class="data">
      <div class="container">
        <ul class="list d_flex">
          <li class="item">
            <h4 class="title">
              <number
                v-scrollanimation
                class="delay-1 fade-in-bottom"
                animationPaused
                ref="number1"
                :from="0"
                :to="985"
                :duration="3"
                easing="Power1.easeOut"/> mi
            </h4>
            <p>Arquivos armazendos</p>
          </li>
          <li class="item">
            <h4 class="title">
              <number 
                v-scrollanimation
                class="delay-2 fade-in-bottom"
                animationPaused
                ref="number2"
                :from="0"
                :to="5"
                :duration="3"
                easing="Power1.easeOut"/> bi</h4>
            <p>E-mails enviados</p>
          </li>
          <li class="item">
            <h4 class="title">
              <number 
                v-scrollanimation
                class="delay-3 fade-in-bottom"
                animationPaused
                ref="number3"
                :from="0"
                :to="700"
                :duration="3"
                easing="Power1.easeOut"/> mi</h4>
            <p>Mensagens enviadas</p>
          </li>
          <li class="item">
            <h4 class="title">
              <number 
                v-scrollanimation
                class="delay-4 fade-in-bottom"
                animationPaused
                ref="number4"
                :from="0"
                :to="235"
                :duration="3"
                easing="Power1.easeOut"/> mi</h4>
            <p>Comunicações realizadas</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="info">
      <NumbersTab />
      <Effects :invert="true" />
    </div>
  </section>
</template>

<script>
import Effects from '../common/Effects.vue';
import NumbersTab from './NumbersTab.vue'
export default {
  name: 'NumbersSection',
  components: {
    NumbersTab,
    Effects,
  },
  methods: {
    verifyPosNumbers() {
      const numbers = [
        this.$refs.number1,
        this.$refs.number2,
        this.$refs.number3,
        this.$refs.number4,
      ];

      numbers.forEach(number => {
        number.$el.classList.contains('enter') 
        ? number.play() 
        : false;
      });
    }
  },
  created() {
    this.verifyPosNumbers;
    window.addEventListener('scroll', this.verifyPosNumbers);
  },
  destroyed() {
    window.removeEventListener('scroll', this.verifyPosNumbers);
  }
};
</script>

<style lang="scss" scoped>
  #numbers {
    background-color: $DarkGrey;
    .data {
      background: url('../../assets/img/bg-numbers.webp') no-repeat center;
      background-size: cover;
      background-attachment: fixed;

      .list {
        flex-wrap: wrap;
        .item {
          flex-basis: 50%;
          padding: 20px;
          color: $White;
          border-right: 2px solid rgba(255,255,255,.2);
          border-bottom: 2px solid rgba(255,255,255,.2);

          &:nth-child(2) {
            border-right: 0;
          }
          &:nth-child(3) {
            border-bottom: 0;
          }
          &:nth-child(4) {
            border: 0;
          }

          @include respond(md-up) {
            flex-basis: 25%;
            &:nth-child(1) {
              border-bottom: 0;
            }
            &:nth-child(2) {
              border-right: 2px solid rgba(255,255,255,.2);
              border-bottom: 0;
            }

            @include respond(md-up) {
              padding: 40px;
            }
          }

          .title {
            margin-bottom: 20px;
            font-size: 24px;
            @include respond(md-up) {
              margin-bottom: 20px;
              font-size: 32px;
              line-height: normal;
            }
          }
          p {
            margin: 0;
            min-height: 36px;
            font-size: 12px;
            line-height: 18px;
            text-transform: uppercase;
            @include respond(md-up) {
              min-height: 40px;
              font-size: 16px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
</style>