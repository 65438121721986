<template>
  <main class="video_interactive">
    <header class="header">
      <title>Vídeo Interativo | Data Core Solutions</title>
      <meta
        name="description"
        content="Personifique e atraia a atenção do usuário, produza diálogos interativos com alto
            grau de resposta positiva.">
      <div class="container">
        <h1 v-scrollanimation class="title delay-1 fade-in-bottom">
          Vídeo Interativo
        </h1>
        <p v-scrollanimation class="delay-2 fade-in-bottom">
          Inovação para sua empresa.
        </p>
      </div>
    </header>
    <section class="section what">
      <div class="container">
        <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">
          O que é um vídeo interativo personalizado?
        </h3>
        <div class="content d_flex">
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            É uma experiência única ao usuário! O vídeo interativo personifica e
            atrai a atenção do usuário, produz diálogos interativos com alto
            grau de resposta positiva. Aumenta a velocidade, abrangência e
            volume de vendas. Atende os clientes com menor custo e reduz o
            volume de chamada nas centrais de atendimento da sua empresa. É uma
            nova e melhor forma de comunicação com o público.
          </p>
        </div>
      </div>
    </section>
    <section class="section video">
      <div class="container" style="padding-top: 0;">
        <div class="video" style="overflow: hidden; padding-bottom: -20px;">
          <!-- Keep video player larger than 320px and keep video player within the size of the viewport. 177.778vh is the aspect ratio 16/9: 100vh * (16/9). -->
          <div id="div--iframe-eov">
              <!-- maintain aspect ratio of video player. -->
              <div id="iframe-eov">
                  <iframe id="iframe_video" src="https://us-east-1-mt-prod2.engageone.video/video_interativo_datacoress/landingpage_site" frameborder="0" scrolling="no"></iframe>
              </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section images">
      <ul class="list d_flex">
        <li class="item">
          <img
            v-scrollanimation
            class="delay-1 scale-in-center"
            src="../../assets/img/produtos/video_interativo_estudio.jpg"
            alt="Estudio para construir o produto"
          />
        </li>
        <li class="item">
          <img
            v-scrollanimation
            class="delay-2 scale-in-center"
            src="../../assets/img/produtos/video_interativo_gravacao.jpg"
            alt="Gravação de um projeto"
          />
        </li>
        <li class="item">
          <img
            v-scrollanimation
            class="delay-3 scale-in-center"
            src="../../assets/img/produtos/video_interativo_cameras.jpg"
            alt="Detalhes dos equipamentos"
          />
        </li>
      </ul>
    </section>
    <section class="section features">
      <div class="container d_flex">
        <div class="demo">
          <i
            v-scrollanimation
            class="icon icon-video_interactive delay-1 fade-in-bottom"
          ></i>
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            Inove e possibilite experiências inesquecíveis aos seus clientes! Dê
            asas à imaginação para encontrar aplicabilidades e dê o grande poder
            para o espectador interagir com o vídeo.
          </p>
          <!-- <Button
            v-show="!isMobile"
            :addClass="'delay-3'"
            :label="'Ver demonstração'" 
            @clickButton="showDemo"/> -->
        </div>
        <ul class="list">
          <li v-scrollanimation class="item delay-1 fade-in-bottom">
            <h4 class="title">Vídeo Interativo</h4>
            <p>
              O áudio visual tem cada vez mais ganhado força na atualidade.
              Vídeos interativos geram maior interação do consumidor com o
              produto ou serviço. Personalize a experiência de vídeo com dados
              do cliente e dados do ambiente em tempo real e torne a jornada do
              público muito mais atrativa.
            </p>
          </li>
          <li v-scrollanimation class="item delay-2 fade-in-bottom">
            <h4 class="title">Aumento de Engajamento com os Cliente</h4>
            <p>
              O áudio visual tem cada vez mais ganhado força na atualidade.
              Vídeos interativos geram maior interação do consumidor com o
              produto ou serviço. Personalize a experiência de vídeo com dados
              do cliente e dados do ambiente em tempo real e torne a jornada do
              público muito mais atrativa.
            </p>
          </li>
          <li v-scrollanimation class="item delay-3 fade-in-bottom">
            <h4 class="title">Combinação de Serviços</h4>
            <p>
              Ofereça serviços específicos para cada cliente, de acordo com as
              opções selecionadas. Disponíveis também para dispositivos móveis.
            </p>
          </li>
          <li v-scrollanimation class="item delay-4 fade-in-bottom">
            <h4 class="title">Aumento dos KPIs de Satisfação dos Clientes</h4>
            <p>
              Mensure detalhadamente o sucesso da ferramenta. Diversos
              marcadores fornecerão informações sobre a satisfação do espectador
              ao assistir o vídeo. Conseguir analisar quais canais são mais
              eficazes, mensurar o sucesso com os funis de conversão, visualizar
              insights de otimização e dados para integração em outros sistemas,
              são apenas alguns dos KPIs que podem ajudar a melhorar os
              resultados.
            </p>
          </li>
        </ul>
      </div>
    </section>

    <!-- <ModalIframe
      :src="'https://www.youtube.com/embed/djV11Xbc914'"
      :title="'YouTube video player'"
      :addClass="demoActive ? 'active' : ''"
    /> -->
  </main>
</template>
<script>
// import Button from '../../components/common/Button.vue';
// import ModalIframe from '../../components/ModalIframe.vue';
export default {
  components: {
    // Button,
    // ModalIframe,
  },
  name: "VideoInteractive",
  methods: {
    showDemo() {
      this.$store.commit("overlayStore/setOverlay", true);
      this.$store.commit("modalStore/setModalVisibility", true);
    },
  },
  computed: {
    demoActive() {
      return this.$store.getters["modalStore/getModalIsActive"];
    },
  },
};

window.addEventListener('load', function () {
  try {
    //Get url params and save to sessionStorage
    const url = new URL(window.location.href);
    const campaign = url.searchParams.get("campanha");

    if (campaign) {
      const videoIframe = document.getElementById("iframe_video");
      
      videoIframe.src = videoIframe.src + "?campanha=" + campaign;
    }

  } catch (error) {
    console.log(error);
  }
})

</script>