<template>
  <section id="products" class="section">
    <div class="container">
      <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">
        Produtos
      </h3>
      <splide
        v-scrollanimation
        class="delay-2 fade-in-bottom"
        :options="options"
      >
        <splide-slide
          class="list"
          v-for="product in products"
          :key="product.id"
        >
          <ProductCard
            v-scrollanimation
            :data="product"
            :class="'flip-vertical-left delay-' + product.id"
          >
          </ProductCard>
        </splide-slide>
      </splide>
    </div>
    <Effects :invert="false" />
  </section>
</template>
<script>
import ProductCard from "./ProductCard.vue";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
// import Button from '../common/Button.vue';
import Effects from "../common/Effects.vue";
export default {
  name: "ProductSection",
  components: {
    ProductCard,
    Splide,
    SplideSlide,
    // Button,
    Effects,
  },

  data() {
    return {
      // Configuração do Slide
      options: {
        width: 400,
        perMove: 1,
        perPage: 1,
        speed: 600,
        rewind: true,
        arrows: false,
        gap: "30px",
        keyboard: true,
      },
      // Informações dos produtos
      products: [
        {
          id: 1,
          img: require("../../assets/svg/formatador_unico_icon.svg"),
          width: 100,
          height: 100,
          alt: "Ícone Formatador Único",
          title: "Formatador Único",
          subtitle: "Entrega de performace em templates.",
          text: "Garanta a entrega de milhares de templates em questões de minutos!",
          route: "/produtos/formatador-unico",
        },
        {
          id: 2,
          img:  require("../../assets/svg/repositorio_unico_icon.svg"),
          width: 100,
          height: 100,
          alt: "Ícone Formatador Único",
          title: "Repositório Único",
          subtitle: "Segurança em bilhões de comunicações online.",
          text: "Reemita facilmente as comunicações em canais de impressão e digitais!",
          route: "/produtos/repositorio-unico",
        },
        {
          id: 3,
          img: require("../../assets/svg/integrador_de_dados_icon.svg"),
          width: 100,
          height: 100,
          alt: "Ícone Formatador Único",
          title: "Integrador de Dados",
          subtitle: "Automatização de dados.",
          text: "Faça rastreamento, automatizações e acelerações do seu banco de dados!",
          route: "/produtos/integrador-de-dados",
        },
        {
          id: 4,
          icon: "icon-video_interactive",
          title: "Vídeo Interativo",
          subtitle: "Evolua para um vídeo que interage!",
          text: "Seja capaz de tratar cada cliente de forma interativa e personalizada com nossa plataforma de vídeos.",
          route: "/produtos/video-interativo",
        },
        {
          id: 5,
          icon: "icon-chatbot",
          title: "Chatbot",
          subtitle: "Atendimento aos clientes 24/7/365",
          text: "Automatize e agilize o atendimento com seu cliente com inteligência artificial no chatbot!",
          route: "/produtos/chatbot",
        },
        {
          id: 6,
          icon: "icon-sms",
          title: "Gerenciador de Comunicações - CCM",
          subtitle: "Personalização e ganho de tempo.",
          text: "Garanta o contato com todos os seus clientes em poucos minutos via e-mail e SMS!",
          route: "/produtos/gerenciador-de-comunicacoes",
        },
      ],
    };
  },
  methods: {
    checkProductDisplay() {
      const isMobile = this.$data.isMobile;
      this.options.perPage = isMobile ? 1 : 3;
      this.options.width = isMobile ? 400 : 1040;
    },
  },
  async created() {
    this.checkProductDisplay();
  },
};
</script>

<style lang="scss" scoped>
#products {
  background-color: $DarkGrey;

  .container {
    position: relative;
    z-index: 2;

    .list.prods {
      flex-wrap: nowrap;
      align-items: stretch;
    }
    button.icon {
      position: relative;
      left: 580px;
      top: 210px;
      color: $TitleColor;
      font-size: 40px;
      background: transparent;
      border: transparent;
    }
  }
}
</style>
