<template>
  <main class="data_integrator">
    <header class="header">
      <div class="container">
        <h1 v-scrollanimation class="title delay-1 fade-in-bottom">
          Integrador de Dados
        </h1>
        <p v-scrollanimation class="delay-2 fade-in-bottom">
          Comunicação precisa e consistente de dados integrados multicanal.
        </p>
      </div>
    </header>
    <section class="section what">
      <div class="container">
        <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">
          O que é o integrador de dados?
        </h3>
        <div class="content d_flex">
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            Imagine a sua empresa, com uma base de dados ativa e estratégica,
            que te levam aos resultados de negócios que você quer, de forma
            precisa e consistente. Quando você entende a sua base de dados, o
            rastreamento e como funciona, você pode automatizar e acelerar as
            decisões, para adicionar, aumentar e reter clientes, reduzindo
            custos e gerenciando riscos. Isto é possível com o integrador de
            dados. Uma plataforma SaaS, inteligente e dinâmica que oferece
            suporte, onde quer que esteja no ciclo da jornada de vida dos seus
            clientes. Você pode escolher apenas os módulos que necessita, que
            irão trabalhar e escalonar juntos simultaneamente outras soluções.
            Com alta qualidade, a sua base de dados fica disponível, onde e
            quando você necessitar, observando e detectando problemas. Construa
            suas possibilidades, cresça o seu negócio com agilidade através da
            integração de um banco de dados realmente confiável.
          </p>
        </div>
      </div>
    </section>
    <section class="section images">
      <ul class="list d_flex">
        <li class="item">
          <img
            v-scrollanimation
            class="delay-1 scale-in-center"
            src="../../assets/img/produtos/integrador_de_dados_img1.jpg"
            alt="Reuniões em Escritório"
          />
        </li>
        <li class="item">
          <img
            v-scrollanimation
            class="delay-2 scale-in-center"
            src="../../assets/img/produtos/integrador_de_dados_img2.jpg"
            alt="Trabalho em Notebook"
          />
        </li>
        <li class="item">
          <img
            v-scrollanimation
            class="delay-3 scale-in-center"
            src="../../assets/img/produtos/integrador_de_dados_img3.jpg"
            alt="Reuniões de Projetos"
          />
        </li>
      </ul>
    </section>
    <section class="section features">
      <div class="container d_flex">
        <div class="demo">
         <img v-scrollanimation class="icons-text delay-1 fade-in-bottom" src="../../assets/svg/integrador_de_dados_icon.svg" alt="Integrador de Dados">
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            Integre dados com alta performance, de forma segura e ágil para uma
            entrega mais assertiva ao seu cliente.
          </p>
        </div>
        <ul class="list">
          <li v-scrollanimation class="item delay-1 fade-in-bottom">
            <h4 class="title">Qualidade dos Dados</h4>
            <p>
              Verifique dados escalonáveis, padronize e crie correspondências
              para toda a jornada de vida do seu cliente.
            </p>
          </li>
          <li v-scrollanimation class="item delay-2 fade-in-bottom">
            <h4 class="title">Integridade de Dados</h4>
            <p>
              Você pode obter alta performance na integridade dos dados através
              de uma plataforma robusta, com maior capacidade de entrega.
            </p>
          </li>
          <li v-scrollanimation class="item delay-3 fade-in-bottom">
            <h4 class="title">Flexibilidade de Implantação</h4>
            <p>
              Suporta sua infraestrutura, desde um único servidor até grandes
              bancos de dados e nuvens.
            </p>
          </li>
        </ul>
      </div>
    </section>

    <!-- <ModalIframe 
      :src="'https://www.youtube.com/embed/m7Bc3pLyij0'"
      :title="'YouTube video player'"
      :addClass="demoActive ? 'active' : ''"
    /> -->
  </main>
</template>
<script>
// import Button from '../../components/common/Button.vue';
// import ModalIframe from '../../components/ModalIframe.vue';
export default {
  components: {
    // Button,
    // ModalIframe,
  },
  name: "DataIntegrator",
  methods: {
    showDemo() {
      this.$store.commit("overlayStore/setOverlay", true);
      this.$store.commit("modalStore/setModalVisibility", true);
    },
  },
  computed: {
    demoActive() {
      return this.$store.getters["modalStore/getModalIsActive"];
    },
  },
};
</script>