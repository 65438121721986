<template>
  <main id="not-found">
    <header class="header">
      <div class="container">
        <h1 v-scrollanimation class="title">Ooops!</h1>
        <p>Não conseguimos encontrar sua solicitação.</p>
      </div>
    </header>
    <section class="section container">
      <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">Página não encontrada</h3>
      <p>Lamentamos, não foi encontrada nenhuma página correspondente a sua pesquisa. Se desejar pode voltar a <router-link to="/">Página Inicial</router-link>.</p>
      <div class="erro">
        <span class="delay-1 floating-number">4</span>
        <span class="delay-2 floating-number">0</span>
        <span class="delay-3 floating-number">4</span>
      </div>
      <Button :route="'/'" :label="'Voltar para Home'"/>
    </section>
  </main>
</template>

<script>
import Button from '../components/common/Button.vue';
export default {
  components: { Button },
  name: 'Privacy',
};
</script>

<style lang="scss" scoped>
  #not-found {
    text-align: center;
    .section {
      .title {
        margin-bottom: 20px;
        color: $TitleColor;
        font-size: 18px;
        text-align: center;
        text-transform: uppercase;
      }

      p {
        max-width: 600px;
        margin: 0 auto;

        a {
          color: $PrimaryColor;
        }
      }

      .erro {
        margin: 40px 0;
        font-size: 40px;
        font-weight: 900;

        @include respond(md-up) {
          margin: 80px 0;
          font-size: 80px;
        }

        span {
          display: inline-block;
          animation-iteration-count: infinite;
        }
      }

      .button {
        margin: 0 auto;
      }
    }
  }
</style>