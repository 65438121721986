<template>
  <section id="caseStudy" class="section">
    <div class="container">
      <h3 v-scrollanimation class="title_section case delay-2 fade-in-bottom">
        Histórias de Sucesso em Destaque
      </h3>
      <splide
        v-scrollanimation
        class="delay-2 fade-in-bottom"
        :options="options"
      >
        <splide-slide
          class="list"
          v-for="caseStudy in caseStudy"
          :key="caseStudy.id">
          <CaseCard
            v-scrollanimation
            :data="caseStudy"
            :class="'flip-vertical-left delay-' + caseStudy.id"
          >
          </CaseCard>
        </splide-slide>
      </splide>
    </div>
    <Effects :invert="false" />
  </section>
</template>
<script>
import CaseCard from "./CaseCard.vue";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
// import Button from '../common/Button.vue';
import Effects from "../common/Effects.vue";
export default {
  name: "CaseSection",
  components: {
    CaseCard,
    Splide,
    SplideSlide,
    // Button,
    Effects,
  },

  data() {
    return {
      // Configuração do Slide
      options: {
        width: 400,
        perMove: 1,
        perPage: 1,
        speed: 600,
        rewind: true,
        arrows: false,
        gap: "30px",
        keyboard: true,
      },
      // Informações dos produtos
      caseStudy: [
        {
          id: 1,
          img: require('../../assets/img/icon_bayer.png'),
          width: 162,
          height: 162,
          alt: "Img Bayer",
          title: "Bayer Sou +",
          subtitle: "",
          text: "Como a Bayer obteve sucesso com um Representante Digital, entregando amostras por todo o Brasil.",
          route: "/estudos-de-casos-bayer",
        },
        {
          id: 2,
          img: require('../../assets/svg/icon_vivo.svg'),
          width: 258,
          height: 161,
          alt: "Img Vivo Online",
          title: "Projeto Fatura Online",
          subtitle: "",
          text: "Saiba como a Vivo cria, dispara e armazena milhões de faturas, através de diversos canais, com agilidade e eficiência.",
          route: "/estudos-de-casos-vivo-online",
        },
        {
          id: 3,
          img: require('../../assets/svg/icon_vivo.svg'),
          width: 258,
          height: 161,
          alt: "Img Vivo Cobrança",
          title: "Projeto Cobrança",
          subtitle: "",
          text: "Como a Vivo melhorou a recuperação de receita através da automação dos acordos de cobrança.",
          route: "/estudos-de-casos-vivo-cobranca",
        },
      ],
    };
  },
  
  methods: {
    checkCaseStudyDisplay() {
      const isMobile = this.$data.isMobile;
      this.options.perPage = isMobile ? 1 : 3;
      this.options.width = isMobile ? 400 : 1040;
    },
  },
  async created() {
    this.checkCaseStudyDisplay();
  },
};
</script>

<style lang="scss" scoped>
#caseStudy {
  background-color: $DarkGreySecondary;

  .container {
    position: relative;
    z-index: 2;

    .list.prods {
      flex-wrap: nowrap;
      align-items: stretch;
    }
    button.icon {
      position: relative;
      left: 580px;
      top: 210px;
      color: $TitleColor;
      font-size: 40px;
      background: transparent;
      border: transparent;
    }
  }
}
</style>
