import Vue from 'vue';
import Vuex from 'vuex';
import overlayStore from './modules/overlayStore';
import menuStore from './modules/menuStore';
import modalStore from './modules/modalStore';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    overlayStore,
    menuStore,
    modalStore,
  },
});
