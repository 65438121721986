const overlayStore = {
  namespaced: true,
  state: {
    overlayActive: false,
  },
  mutations: {
    setOverlay: (state, value) => {
      state.overlayActive = value;
    },
  },
  getters: {
    getOverlay: (state) => state.overlayActive,
  },
};

export default overlayStore;
