import Vue from 'vue';

// Global Methods
Vue.mixin({
  data() {
    return {
      isMobile: {
        type: Boolean,
        default: false,
      },
    };
  },
  methods: {
    mobileView() {
      this.isMobile = window.innerWidth <= 1023;
    },
  },
  created() {    
    this.mobileView();
    window.addEventListener('resize', this.mobileView);
  },
});