<template>
  <header id="header" :class="'header' + (scrollHeader ? ' scroll' : '')">
    <div class="container d_flex">
      <router-link class="logo" :to="routes[6].path">
        <img class="img" src="../assets/img/logo-datacoress.png" alt="Logo Data Core Solutions" width="433" height="71">
      </router-link>
      <Menu :routes="routes"/>
    </div>
  </header>
</template>

<script>
import { routes } from '../routes';
import Menu from './Menu.vue'

export default {
  name: 'Header',
  components: {
    Menu,
  },
  data() {
    return {
      scrollHeader: false,
      routes,
    };
  },
  methods: {
    menuScroll() {
      if (window.scrollY > 50 || this.$router.currentRoute.path !== '/') {
        this.scrollHeader = true;
      } else {
        this.scrollHeader = false;
      }
    },
  },
  watch: {
    $route () {
      this.menuScroll();
    }
  },
  created() {
    this.menuScroll();
  },
  mounted() {
    window.addEventListener('scroll', this.menuScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.menuScroll);
  },
}
</script>

<style lang="scss" scoped>
#header {
  position: fixed;
  left: 50%;
  top: 0;
  width: 100vw;
  background-color: transparent;
  z-index: 100;
  @include transform(translateX(-50%));
  @include transition(all .3s ease);

  &.scroll {
    background-color: $DarkGrey;
    @include box-shadow(0 6px 12px rgba(0,0,0,.2));
  }

  .container {
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .logo {
    order: 1;
    max-width: 200px;
    @include respond(md-up) {
      order: 0;
      max-width: 280px;
    }
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
</style>