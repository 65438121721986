<template>
  <div id="emphasis" class="section" :style="'background-image: url(' + (require(`../../assets/img/${imgBg}.webp`)) + ')'">
    <div class="container">
      <p v-html="text"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextEmphasis',
  props: {
    imgBg: String,
    text: String,
  },
}
</script>
