<template>
  <main class="single_repository">
    <header class="header">
      <div class="container">
        <h1 v-scrollanimation class="title delay-1 fade-in-bottom">
          Repositório Único
        </h1>
        <p v-scrollanimation class="delay-2 fade-in-bottom">
          Armazenamento Digital com compressão e segurança.
        </p>
      </div>
    </header>
    <section class="section what">
      <div class="container">
        <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">
          O que é um Repositório Único?
        </h3>
        <div class="content d_flex">
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            É uma plataforma de armazenamento digital de compressão avançada que
            permite avançar com segurança, bilhões de comunicações online em seu
            formato original, sem perda de qualidade. Possibilita acesso
            imediato de documentos arquivados quando os usuários precisam de
            respostas rápidas. O Repositório Único simplifica as solicitações de
            reimpressão e permite que você reemita facilmente as comunicações
            tanto nos canais de impressão como nos digitais, além de ser
            escalonável para milhares de clientes com utilização na web.
          </p>
        </div>
      </div>
    </section>
    <section class="section images">
      <ul class="list d_flex">
        <li class="item">
          <img
            v-scrollanimation
            class="delay-1 scale-in-center"
            src="../../assets/img/produtos/repositorio_unico_img1.jpg"
            alt="Reuniões em Escritório"
          />
        </li>
        <li class="item">
          <img
            v-scrollanimation
            class="delay-2 scale-in-center"
            src="../../assets/img/produtos/repositorio_unico_img2.jpg"
            alt="Trabalho em Notebook"
          />
        </li>
        <li class="item">
          <img
            v-scrollanimation
            class="delay-3 scale-in-center"
            src="../../assets/img/produtos/repositorio_unico_img3.jpg"
            alt="Reuniões de Projetos"
          />
        </li>
      </ul>
    </section>
    <section class="section features">
      <div class="container d_flex">
        <div class="demo">
        <img v-scrollanimation class="icons-text delay-1 fade-in-bottom" src="../../assets/svg/repositorio_unico_icon.svg" alt="Repositório Único">
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            Possibilite acesso a arquivos originais em alta performance, com
            fácil acesso dos diversos canais de comunicação da sua empresa, e
            ofereça a melhor experiência ao usuário.
          </p>
        </div>
        <ul class="list">
          <li v-scrollanimation class="item delay-1 fade-in-bottom">
            <h4 class="title">Experiência do Usuário</h4>
            <p>
              Oferece bastante espaço de armazenamento, com acesso integrado dos
              documentos em formato original, para proporcionar melhor
              experiência dos usuários e atendentes da sua empresa.
            </p>
          </li>
          <li v-scrollanimation class="item delay-2 fade-in-bottom">
            <h4 class="title">Armazenamento e Performance</h4>
            <p>
              Com tecnologia avançada, é possível processar e indexar mais de
              2,5 milhões de páginas por hora.
            </p>
          </li>
          <li v-scrollanimation class="item delay-3 fade-in-bottom">
            <h4 class="title">Integração</h4>
            <p>
              Integre diversos canais de comunicação da empresa, tornando a
              comunicação muito mais rápida e eficaz.
            </p>
          </li>
          <li v-scrollanimation class="item delay-4 fade-in-bottom">
            <h4 class="title">Segurança</h4>
            <p>
              Mantenha documentos em seu formato original, protegido pelo
              sistema de segurança de sua empresa.
            </p>
          </li>
        </ul>
      </div>
    </section>

    <!-- <ModalIframe 
      :src="'https://www.youtube.com/embed/m7Bc3pLyij0'"
      :title="'YouTube video player'"
      :addClass="demoActive ? 'active' : ''"
    /> -->
  </main>
</template>
<script>
// import Button from '../../components/common/Button.vue';
// import ModalIframe from '../../components/ModalIframe.vue';
export default {
  components: {
    // Button,
    // ModalIframe,
  },
  name: "SingleRepository",
  methods: {
    showDemo() {
      this.$store.commit("overlayStore/setOverlay", true);
      this.$store.commit("modalStore/setModalVisibility", true);
    },
  },
  computed: {
    demoActive() {
      return this.$store.getters["modalStore/getModalIsActive"];
    },
  },
};
</script>