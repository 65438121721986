<template>
  <main class="mailsms">
    <header class="header">
      <title>Gerenciador de Comunicações | Data Core Solutions</title>
      <meta
        name="description"
        content="Otimize o processo de gestão de comunicação com o
            cliente, crie e entrege de forma simplificada, comunicações digitais
            e utilize templates de e-mails e SMS, automatize o disparo de
            campanhas personalizadas, responsivas com alta eficiência.">
      <div class="container">
        <h1 v-scrollanimation class="title delay-1 fade-in-bottom">
          Gerenciador de Comunicações - CCM
        </h1>
        <p v-scrollanimation class="delay-2 fade-in-bottom">
          Mensagens rápidas administradas em um só lugar.
        </p>
      </div>
    </header>
    <section class="section what">
      <div class="container">
        <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">
          O que é um Gerenciador de Comunicações?
        </h3>
        <div class="content d_flex">
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            Uma plataforma que otimiza o processo de gestão de comunicação com o
            cliente, cria e entrega de forma simplificada, comunicações digitais
            e utiliza templates de e-mails e SMS, automatiza o disparo de
            campanhas personalizadas, responsivas com alta eficiência. É a
            solução ideal, para a organização que busca potencializar a sua
            comunicabilidade com clientes. Quer acesso a painéis para rastrear e
            medir seus resultados? O processo é rápido, simples e intuitivo para
            todos os usuários.
          </p>
        </div>
      </div>
    </section>
    <section class="section images">
      <ul class="list d_flex">
        <li class="item">
          <img
            v-scrollanimation
            class="delay-1 scale-in-center"
            src="../../assets/img/produtos/emailsms_gmail.jpg"
            alt="Caixa de entrada do Gmail"
          />
        </li>
        <li class="item">
          <img
            v-scrollanimation
            class="delay-2 scale-in-center"
            src="../../assets/img/produtos/emailsms_gmail2.jpg"
            alt="Inicialização do Gmail"
          />
        </li>
        <li class="item">
          <img
            v-scrollanimation
            class="delay-3 scale-in-center"
            src="../../assets/img/produtos/emailsms_usuario.jpg"
            alt="Homem com um tablet utilizando os apps"
          />
        </li>
      </ul>
    </section>
    <section class="section features">
      <div class="container d_flex">
        <div class="demo">
          <i v-scrollanimation class="icon icon-sms delay-1 fade-in-bottom"></i>
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            Ofereça aos usuários, experiências personalizadas através dos
            múltiplos canais de comunicação como e-mails, SMS, chatbot e vídeos
            interativos, de forma dinâmica, com excelência no conteúdo
            apresentado.
          </p>
          <!-- <Button
            v-show="!isMobile"
            :addClass="'delay-3'"
            :label="'Ver demonstração'" 
            @clickButton="showDemo"/> -->
        </div>
        <ul class="list">
          <li v-scrollanimation class="item delay-1 fade-in-bottom">
            <h4 class="title">Canais de Comunicação</h4>
            <p>
              Permite a criação e o disparo de comunicação em diversos canais
              distintos ao mesmo tempo.
            </p>
          </li>
          <li v-scrollanimation class="item delay-2 fade-in-bottom">
            <h4 class="title">Versão Web</h4>
            <p>
              Software online, sem a necessidade de instalação no computador dos
              usuários.
            </p>
          </li>
          <li v-scrollanimation class="item delay-3 fade-in-bottom">
            <h4 class="title">Conceito Drag-and-drop</h4>
            <p>
              Oferece a função arrasta e solta. Processo intuitivo, permite que
              todos os usuários consigam utilizar a ferramenta de maneira
              simples, sem conhecimento técnico aprofundado.
            </p>
          </li>
          <li v-scrollanimation class="item delay-4 fade-in-bottom">
            <h4 class="title">Leitura de Dados de Clientes</h4>
            <p>
              Permite a criação de variáveis nas comunicações e alteração de
              forma massiva, utilizando os dados dos clientes, como nome,
              endereço e outras informações base para análise.
            </p>
          </li>
        </ul>
      </div>
    </section>

    <!-- <ModalIframe 
      :src="'https://www.youtube.com/embed/m7Bc3pLyij0'"
      :title="'YouTube video player'"
      :addClass="demoActive ? 'active' : ''"
    /> -->
  </main>
</template>
<script>
// import Button from '../../components/common/Button.vue';
// import ModalIframe from '../../components/ModalIframe.vue';
export default {
  components: {
    // Button,
    // ModalIframe,
  },
  name: "MailSMS",
  methods: {
    showDemo() {
      this.$store.commit("overlayStore/setOverlay", true);
      this.$store.commit("modalStore/setModalVisibility", true);
    },
  },
  computed: {
    demoActive() {
      return this.$store.getters["modalStore/getModalIsActive"];
    },
  },
};
</script>