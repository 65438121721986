<template>
  <main id="caseStudyBayer">
    <header class="header">
      <div class="container">
        <h1 v-scrollanimation class="title delay-1 fade-in-bottom">
          Estudos de Caso
        </h1>
        <p v-scrollanimation class="delay-2 fade-in-bottom">
          Conheça como empresas estão revolucionando a <br>experiência do cliente com soluções da Data Core Solutions.
        </p>
      </div>
    </header>
    <section class="section about">
      <div class="container">
        <h3 v-scrollanimation class="title_section company delay-1 fade-in-bottom">
          Projeto SOU+ <br>Cardio e Nutrição Materna
        </h3>
        <div class="cobrancaProject d_flex">
        <div class="text">
          <p v-scrollanimation class="text delay-2 fade-in-bottom">
            Como a Bayer obteve sucesso com o Representante Digital através do vídeo interativo, entregando amostras por todo o Brasil.
          </p>
        </div>
          <img
            v-scrollanimation
            src="../../assets/svg/icon_bayer.svg"
            alt="Icon Bayer "
            class="icon empresas bayer delay-2 fade-in-bottom"
          />
        </div>
      </div>
    </section>
    <section class="section challenges">
      <div class="container">
        <h3 v-scrollanimation class="title_section delay-1 fade-in-bottom">
          Desafios
        </h3>
         <p v-scrollanimation class="listSquare delay-1 fade-in-bottom">
            <ul class="list square challengesList">
              <li class="item">
                 Aumentar os resultados de prescrição da empresa (Market Share), após a redução de representantes do time comercial.
              </li>
              <li class="item">
                 Ter maior cobertura de representação pelo território nacional e penetrar em lugares mais distantes do país.
              </li>
            </ul>
          </p>
      </div>
    </section>
    <section  v-scrollanimation class="section results delay-2 fade-in-bottom">
      <div class="container">
          <h3 class="title_section">
            Resultados
          </h3>
          <p v-scrollanimation class="text delay-2 fade-in-bottom">
            A Data Core ofereceu para a farmacêutica Bayer, a solução do vídeo interativo:
          </p>
          <h3 v-scrollanimation class="title_section title delay-1 fade-in-bottom">
          O Representante Digital com <br>hiperpersonalização em alto volume.
        </h3>
        <p v-scrollanimation class="text digital delay-2 fade-in-bottom">
            A solução do vídeo interativo, possui acesso através do portal Universo Médico e disponibiliza para o profissional, interação de forma personalizada com a ferramenta, de acordo com a necessidade de cada especialidade médica.
          </p>
          <p v-scrollanimation class="text digital delay-2 fade-in-bottom">
            É possível para os médicos, selecionarem entrega e quantidade de amostras grátis, acessar lançamentos de produtos, interagir com mais facilidade com a empresa quando necessita de informações e melhorar a cobertura de alcance dos profissionais das áreas mais remotas.
          </p>
          <div class="dice">
         <img v-scrollanimation class="icons-text delay-1 fade-in-bottom" src="../../assets/img/estudos_de_caso/bayer/icon_entrega_de_amostras_de_medicamento_representante_digital.png" alt="Icon amostras de medicamento representante digital">
         <h3 v-scrollanimation class="title_section title results medicine delay-1 fade-in-bottom">
          +<number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number1"
                :from="0"
                :to="80"
                :duration="7"
                easing="Power1.easeOut"/>
                mil
        </h3>
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            Amostras de medicamentos entregues
          </p>
        </div>
        <div class="dice">
         <img v-scrollanimation class="icons-text delay-1 fade-in-bottom" src="../../assets/img/estudos_de_caso/bayer/icon_medicos_cadastrados_mailing.png" alt="Icon medicos cadastrados mailing">
         <h3 v-scrollanimation class="title_section title results delay-1 fade-in-bottom">
          +<number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number2"
                :from="0"
                :to="38"
                :duration="7"
                easing="Power1.easeOut"/>
                mil
        </h3>
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            Novos médicos cadastrados
          </p>
        </div>
        <div class="dice">
         <img v-scrollanimation class="icons-text delay-1 fade-in-bottom" src="../../assets/img/estudos_de_caso/bayer/icon_video_interativo_minutos_assistidos_projeto_digital.png" alt="Icon video interativo">
         <h3 v-scrollanimation class="title_section title results interactiveVideo delay-1 fade-in-bottom">
          +<number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number3"
                :from="0"
                :to="12"
                :duration="7"
                easing="Power1.easeOut"/>
                mil
        </h3>
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            Minutos de conteúdo assistidos 
          </p>
        </div>
        <div class="dice">
         <img v-scrollanimation class="icons-text delay-1 fade-in-bottom" src="../../assets/img/estudos_de_caso/bayer/icon_entrega_de_amostras_de_medicamento.png" alt="Icon amostras de medicamento">
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            Aumento da cobertura <br>para <b v-scrollanimation class="title_section title delay-1 fade-in-bottom"><number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number4"
                :from="0"
                :to="82"
                :duration="7"
                easing="Power1.easeOut"/>%</b> dos estados brasileiros
          </p>
        </div>
      </div>
    </section>
    <section class="images">
      <ul class="list d_flex">
        <li class="item">
          <img
            v-scrollanimation
            class="delay-1 scale-in-center"
            src="../../assets/img/estudos_de_caso/bayer/empresa-bayer-projeto-sou.jpg"
            alt="Mulher em gravação de vídeo"
          />
        </li>
        <li class="item">
          <img
            v-scrollanimation
            class="delay-2 scale-in-center"
            src="../../assets/img/estudos_de_caso/bayer/bayer-plataforma-de-cadastro-medicos.jpg"
            alt="Pessoa vendo vídeo no notebook"
          />
        </li>
        <li class="item">
          <img
            v-scrollanimation
            class="delay-3 scale-in-center"
            src="../../assets/img/estudos_de_caso/bayer/bayer-video-interativo-representante-digital.jpg"
            alt="Médico mostrando vídeo pesonalizado"
          />
        </li>
      </ul>
    </section>
    <section class="section history">
      <div class="container">
          <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">
            História
          </h3>
        <div v-scrollanimation class="text d_flex delay-2 fade-in-bottom">
          <p>
            Em 2015, a empresa farmacêutica Bayer sentiu a necessidade de mudanças nos processos digitais para proporcionar a melhor experiência ao seu principal cliente de forma personalizada, o médico.
          </p>
          <p>
            Neste momento, observou que Health Techs apresentavam grande importância na digitalização do relacionamento com os clientes. Com o avanço da tecnologia, elementos de Customer Experience (CX), ganharam ainda mais força no cenário mundial e em pesquisa, encontrou a solução da Data Core Solutions.
          </p>
          <p>
            Em 2016, a Data Core iniciou a parceria com a Bayer, implementando a solução do Vídeo Interativo e revolucionando a forma com que o médico se relaciona com a indústria farmacêutica, melhorando a sua experiência com personalização, agilidade e de forma intuitiva.
          </p>
          <p>
            É possível com a ferramenta, que o médico(a) selecione a entrega e quantidade de amostras de medicamentos, cesse lançamentos de produtos, interaja com mais facilidade com a empresa quando necessita de informações e a solução também melhora a cobertura de alcance dos profissionais das áreas mais remotas.
          </p>
          <div v-scrollanimation class="text2 d_flex delay-2 fade-in-bottom">
          <p>
            “A tecnologia complementa às grandes empresas tradicionais do mercado, uma capacidade incrível de melhores resultados com mais autonomia e menosbarreiras territoriais.”
          </p>
          </div>
          </div>
          <p>
            Head of Sales da Data Core Solutions, Caio Aguiar
          </p>
          <h3 v-scrollanimation class="title_section section delay-2 fade-in-bottom">
            Benefícios
          </h3>
          <p v-scrollanimation class="listSquare delay-1 fade-in-bottom">
            <ul class="list square benefits">
              <li class="item">
                 Lançamentos de produtos/medicamentos.
              </li>
              <li class="item">
                 Redução de custos na empresa.
              </li>
              <li class="item">
                 Expansão de contatos, permitindo acesso de profissionais que atuam em locais mais remotos.
              </li>
              <li class="item">
                 Solicitação digital de amostras de medicamentos com segurança, agilidade de forma intuitiva e <br>personalizada.
              </li>
              <li class="item">
                 Acesso aos conteúdos e informações relevantes aos profissionais de acordo com a especialidade <br>médica.
              </li>
              <li class="item">
                 Relacionamento médico em alto volume, com mais acessos aos médicos(as) e informação <br>mais rápida do laboratório farmacêutico ao profissional.
              </li>
            </ul>
          </p>
      </div>
    </section>
    <section class="section about clients">
      <div class="container">
        <h3 v-scrollanimation class="title_section delay-1 fade-in-bottom">
          Sobre a Bayer
        </h3>
        <div class="content d_flex">
          <img
            v-scrollanimation
            src="../../assets/img/estudos_de_caso/bayer/empresa-bayer-sede.jpg"
            alt="Bayer empresa"
            class="company delay-2 fade-in-bottom"
          />
        <div class="text">
          <p v-scrollanimation class="company delay-2 fade-in-bottom">
            A Bayer foi fundada em 1863 na Alemanha, pelos amigos Friedrich Bayer e Johann Friedrich Weskott, que realizavam experimentos e tentavam descobrir como produzir o corante fucsina. Em 1881 a Bayer foi transformada em uma sociedade por ações e entre os anos de 1881 e 1914, a Bayer se desenvolveu em uma empresa química com operações internacionais. Desestabilizada pela primeira e segunda guerra mundial, continuou investindo em pesquisa e tecnologia e na década de 90, a empresa comemorou os cem anos da Aspirina.
          </p>
          <p v-scrollanimation class="company delay-2 fade-in-bottom">
            Atualmente a Bayer atua nos segmentos de saúde (HealthCare), agricultura (CropScience), materiais de alta tecnologia (MaterialScience) e é uma das maiores empresas do setor no mundo.
          </p>
        </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "CasdeStudyBayer",
  methods: {
    verifyPosNumbers() {
      const numbers = [
        this.$refs.number1,
        this.$refs.number2,
        this.$refs.number3,
        this.$refs.number4,
      ];
      numbers.forEach((number) => {
        number.$el.classList.contains("enter") ? number.play() : false;
      });
    },
  },
  created() {
    this.verifyPosNumbers;
    window.addEventListener("scroll", this.verifyPosNumbers);
  },
  destroyed() {
    window.removeEventListener("scroll", this.verifyPosNumbers);
  },
};
</script>

<style lang="scss" scoped>
#caseStudyBayer {
  .about,
  .why {
    background-color: $DarkGrey;

    .title_section {
      &.company {
        text-align: left;
        margin-bottom: 0;
      }
    }

    .content {
      align-items: initial;

      @include respond(sm1-up) {
        display: inline-block;
      }
    }

    .text {
      width: 82%;

      @include respond(sm1-up) {
        width: 100%;
      }

      p {
        &.company {
          width: 100%;
          margin: -43px 22px;
          padding: 39px;

          @include respond(sm1-up) {
            padding: 20px 0;
            margin: 0 auto;
          }
        }
      }
    }

    img {
      width: 18%;

      @include respond(sm1-up) {
        width: 100%;
      }

      &.company {
        width: 45%;
        filter: saturate(0);

        &:hover {
          filter: saturate(1);
          transition: all 0.3s ease;
        }

        @include respond(sm1-up) {
          width: 100%;
        }
      }
    }
  }

  .header {
    background: url(../../assets/img/estudos_de_caso/header_estudos_de_caso.jpg)
      no-repeat center !important;
    background-size: cover !important;
  }

  .cobrancaProject {
    @include respond(sm1-up) {
      display: inline-block;
    }
  }

  .challenges {
    background-color: $DarkGreySecondary;

    .challengesList {
      @include respond(sm2-up) {
        margin: 0 auto;
      }

      @include respond(md-up) {
        margin: 0 243px;
      }
    }
  }

  .clients {
    background-color: $DarkGreySecondary;

    .container {
      @include respond(md-up) {
        padding: 80px 30px;
      }
    }
  }

  .gallery {
    background-color: $DarkGrey;
  }

  .images {
    background-color: $DarkGrey;
  }

  .results {
    @include respond(sm1-up) {
      margin: 0 auto;
    }

    .title {
      font-size: 30px;
      text-transform: none;
      margin-top: 50px;

      &.results {
        margin-top: 0;
        margin-bottom: 0;

        &.medicine {
          margin-top: 26px;
        }

        &.interactiveVideo {
          margin-top: 29px;
        }
      }
    }

    .digital {
      text-align: justify;

      @include respond(md-up) {
        padding: 0 150px !important;
      }

      @include respond(sm1-up) {
        padding: 0;
      }

      @include respond(sm2-up) {
        padding: 0;
      }
    }

    .dice {
      margin: 0 20px 100px;
      float: left;

      @include respond(xl-up) {
        width: calc(21.6%) !important;
      }

      @include respond(sm2-up) {
        width: calc(18%);
      }

      @include respond(md-up) {
        width: calc(20%);
      }

      img {
        margin: 30px auto 20px;
      }

      p {
        margin: 0;
      }

      @include respond(sm1-up) {
        width: auto;
        margin: 0 100px;
        flex-direction: column;
        float: none;
      }

      h3 {
        text-align: center;
        margin-bottom: 20px;
      }

      p {
        @include respond(md-up) {
          &:first-child {
            padding-right: 0px !important;
          }

          &:last-child {
            padding-left: 0px !important;
          }
        }
      }
    }

    ul,
    .listSquare {
      text-align: center;
      display: block;
      padding-right: 5px;
    }
  }
  .history {
    background-color: $DarkGrey;

    .text {
      text-align: center;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 50px;

      @include respond(sm1-up) {
        width: auto;
        margin-right: 0;
        flex-direction: column;
        float: none;
      }
    }

    .text2 {
      flex-direction: column;
      padding: 0 170px;
      text-align: center;
      font-size: 27px;
      font-weight: bold;
      margin-bottom: -60px;
      @include respond(sm1-up) {
        width: auto;
        margin-right: 0;
        flex-direction: column;
        float: none;
        padding: 0;
      }
    }

    h3 {
      margin-bottom: 20px;

      &.title {
        margin: 30px 0 0;
      }

      &.section {
        margin-top: 90px;
      }
    }

    .benefits {
      text-align: center;
    }
  }
}
</style>