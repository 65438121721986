<template>
  <div 
    id="overlay"
    :class="hasOverlay ? 'active' : ''"
    @click="hideOverlay">
  </div>
</template>
<script>
export default {
  name: 'Overlay',
  methods: {
    hideOverlay() {
      this.$store.commit('menuStore/setMenuVisibility', false);
      this.$store.commit('modalStore/setModalVisibility', false);
      this.$store.commit('overlayStore/setOverlay', false);
    },
  },
  computed: {
    hasOverlay() {
      return this.$store.getters['overlayStore/getOverlay'];
    },
  },
}
</script>

<style lang="scss" scoped>
  #overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .6);
    z-index: 50;
    opacity: 0;
    pointer-events: none;
    @include transition(all .3s ease);

    &.active {
      opacity: 1;
      pointer-events: auto;
    }
  }
</style>