<template>
  <main id="caseStudyVivoCobranca">
    <header class="header">
      <div class="container">
        <h1 v-scrollanimation class="title delay-1 fade-in-bottom">
          Estudos de Caso
        </h1>
        <p v-scrollanimation class="delay-2 fade-in-bottom">
          Conheça como empresas estão revolucionando a <br>experiência do cliente com soluções da Data Core Solutions.
        </p>
      </div>
    </header>
    <section class="section about">
      <div class="container">
        <h3 v-scrollanimation class="title_section company delay-1 fade-in-bottom">
          Projeto Cobrança
        </h3>
        <div class="cobrancaProject d_flex">
        <div class="text">
          <p v-scrollanimation class="text delay-2 fade-in-bottom">
            Como a Vivo melhorou a recuperação de receita através da automação dos acordos de cobrança.
          </p>
        </div>
          <img
            v-scrollanimation
            src="../../assets/svg/icon_vivo.svg"
            alt="Icon Vivo"
            class="icon empresas delay-2 fade-in-bottom"
          />
        </div>
      </div>
    </section>
    <section class="section challenges">
      <div class="container">
        <h3 v-scrollanimation class="title_section delay-1 fade-in-bottom">
          Desafios
        </h3>
         <p v-scrollanimation class="listSquare delay-1 fade-in-bottom">
            <ul class="list square challengesList">
              <li class="item">
                 Criar processo integrado com as assessorias de cobranças para recepção de dados e geração de acordo de pagamento de forma online e massiva, tanto para meio impresso quanto para envio por e-mail aos clientes.
              </li>
              <li class="item">
                 De acordo com a idade da dívida, disponibilizar formatos de comunicação diferentes para avisar ao cliente.
              </li>
            </ul>
          </p>
      </div>
    </section>
    <section  v-scrollanimation class="section results delay-2 fade-in-bottom">
      <div class="container">
          <h3 class="title_section ">
            Resultados
          </h3>
        <div class="dice">
         <img v-scrollanimation class="icons-text result delay-1 fade-in-bottom" src="../../assets/img/estudos_de_caso/vivo_cobranca/icon_processamento_mais_eficiente_dos_acordos.png" alt="Icon processamento mais eficiiente de acordos">
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            Processamento mais eficiente dos <br>acordos de pagamentos e <br>melhores resultados de cobrança.
          </p>
        </div>
        <div class="dice">
         <img v-scrollanimation class="icons-text delay-1 fade-in-bottom" src="../../assets/img/estudos_de_caso/vivo_cobranca/icon_recuperacao_da_receita.png" alt="Icon recuperacao da receita">
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            Melhores resultados na <br>recuperação de receita.
          </p>
      </div>
      <div class="dice">
         <img v-scrollanimation class="icons-text result sms delay-1 fade-in-bottom" src="../../assets/img/estudos_de_caso/vivo_cobranca/icon_entrega_mais_eficiente_e_rapida.png" alt="Icon entrega eficiente">
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            Entrega mais eficiente e rápida em <br>diversos canais (email, SMS, impressa), fatura no <br>portal (área logada), fatura pelo APP e <br>fatura pela URA (call center).
          </p>
      </div>
        <h3 class="title_section table">
            Acordos Online
          </h3>
      <table v-scrollanimation class=" table delay-1 fade-in-bottom" rules="none">
        <tr>
          <td class="info">Ano</td>
          <td class="info"><b>2021</b></td>
          <td class="info"><b>2022</b></td>
        </tr>
        <tr>
          <td class="info">Quantidade de <br>acordos</td>
          <td class="title_section infos"><b><number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number1"
                :from="0"
                :to="3"
                :duration="7"
                easing="Power1.easeOut"/>.<number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number2"
                :from="0"
                :to="473"
                :duration="7"
                easing="Power1.easeOut"/>.<number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number3"
                :from="0"
                :to="563"
                :duration="7"
                easing="Power1.easeOut"/></b></td>
          <td class="title_section infos"><b><number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number4"
                :from="0"
                :to="4"
                :duration="7"
                easing="Power1.easeOut"/>.<number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number5"
                :from="0"
                :to="631"
                :duration="7"
                easing="Power1.easeOut"/>.<number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number6"
                :from="0"
                :to="394"
                :duration="7"
                easing="Power1.easeOut"/></b></td>
        </tr>
        <tr>
          <td class="info">Número de <br>clientes</td>
          <td class="title_section infos"><b><number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number7"
                :from="0"
                :to="2"
                :duration="7"
                easing="Power1.easeOut"/>.<number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number8"
                :from="0"
                :to="645"
                :duration="7"
                easing="Power1.easeOut"/>.<number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number9"
                :from="0"
                :to="144"
                :duration="7"
                easing="Power1.easeOut"/></b></td>
          <td class="title_section infos"><b><number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number10"
                :from="0"
                :to="3"
                :duration="7"
                easing="Power1.easeOut"/>.<number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number11"
                :from="0"
                :to="337"
                :duration="7"
                easing="Power1.easeOut"/>.<number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number12"
                :from="0"
                :to="587"
                :duration="7"
                easing="Power1.easeOut"/></b></td>
        </tr>
      </table>
      <h3 class="title_section table">
            Acordos Massivos
          </h3>
      <table v-scrollanimation class=" table delay-1 fade-in-bottom" rules="none">
        <tr>
          <td class="info">Ano</td>
          <td class="info"><b>2021</b></td>
          <td class="info"><b>2022</b></td>
        </tr>
        <tr>
          <td class="info">Quantidade de <br>acordos</td>
          <td class="title_section infos"><b><number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number13"
                :from="0"
                :to="24"
                :duration="7"
                easing="Power1.easeOut"/>.<number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number14"
                :from="0"
                :to="552"
                :duration="7"
                easing="Power1.easeOut"/>.<number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number15"
                :from="0"
                :to="910"
                :duration="7"
                easing="Power1.easeOut"/></b></td>
          <td class="title_section infos"><b><number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number16"
                :from="0"
                :to="18"
                :duration="7"
                easing="Power1.easeOut"/>.<number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number17"
                :from="0"
                :to="174"
                :duration="7"
                easing="Power1.easeOut"/>.<number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number18"
                :from="0"
                :to="271"
                :duration="7"
                easing="Power1.easeOut"/></b></td>
        </tr>
        <tr>
          <td class="info">Número de <br>clientes</td>
          <td class="title_section infos"><b><number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number19"
                :from="0"
                :to="7"
                :duration="7"
                easing="Power1.easeOut"/>.<number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number20"
                :from="0"
                :to="294"
                :duration="7"
                easing="Power1.easeOut"/>.<number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number21"
                :from="0"
                :to="195"
                :duration="7"
                easing="Power1.easeOut"/></b></td>
          <td class="title_section infos"><b><number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number22"
                :from="0"
                :to="8"
                :duration="7"
                easing="Power1.easeOut"/>.<number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number23"
                :from="0"
                :to="812"
                :duration="7"
                easing="Power1.easeOut"/>.<number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number24"
                :from="0"
                :to="230"
                :duration="7"
                easing="Power1.easeOut"/></b></td>
        </tr>
      </table>
      </div>
    </section>
    <section class="images">
      <ul class="list d_flex">
        <li class="item">
          <img
            v-scrollanimation
            class="delay-1 scale-in-center"
            src="../../assets/img/estudos_de_caso/vivo_cobranca/projeto_vivo_acordos_de_cobranca.jpg"
            alt="Homem escrevendo em uma folha de papel"
          />
        </li>
        <li class="item">
          <img
            v-scrollanimation
            class="delay-2 scale-in-center"
            src="../../assets/img/estudos_de_caso/vivo_cobranca/projeto_vivo_acordos_de_cobranca2.jpg"
            alt="Mulher lendo uma folha de papel"
          />
        </li>
        <li class="item">
          <img
            v-scrollanimation
            class="delay-3 scale-in-center"
            src="../../assets/img/estudos_de_caso/vivo_cobranca/projeto_vivo_acordos_de_cobranca3.jpg"
            alt="Mulher mexendo no celular"
          />
        </li>
      </ul>
    </section>
    <section class="section history">
      <div class="container">
          <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">
            História
          </h3>
        <div v-scrollanimation class="text d_flex delay-2 fade-in-bottom">
          <p>
            O Fundador e CEO da Data Core Solutions, Manuel Alejandro Reyes Bustos, iniciou a implementação do projeto na empresa Vivo, parceria já de longa data. Isso ocorreu devido a confiança construída especialmente pela qualidade do suporte prestado pela Data Core ao projeto.
          </p>
          <h3 v-scrollanimation class="title delay-1 fade-in-bottom">
          As soluções utilizadas no projeto:
        </h3>
          <p v-scrollanimation class="listSquare delay-1 fade-in-bottom">
            <ul class="list square">
              <li class="item">
                 APIs - para comunicação com as assessorias e criação de acordo online.
              </li>
              <li class="item">
                Formatador Único - para formatar o design, processar online ou massivamente a cartas e boletos do acordo de pagamento, bem como as comunicações de email e SMS para notificar o cliente do acordo e das parcelas futuras.
              </li>
              <li class="item">
                Repositório Único - para comprimir 90% do tamanho do PDF gerado do acordo, indexar os arquivos para permitir o armazenamento correto e permitir a recuperação por API para qualquer canal de atendimento e autoatendimento, garantindo agilidade e rapidez para o documento de acordo.
              </li>
              <li class="item">
                Disparador de Comunicações - para enviar email e SMS ao cliente, demonstrando através de relatórios a efetividade de processamento e entrega das mensagens.
              </li>
            </ul>
          </p>
          </div>
          <div v-scrollanimation class="text2 d_flex delay-2 fade-in-bottom">
          <p>
            “O maior benefício do projeto é a maior eficiência na recuperação da receita.”
          </p>
          </div>
          <p>
            Manuel Alejandro Reyes Bustos
          </p>
          <h3 v-scrollanimation class="title_section section delay-2 fade-in-bottom">
            Benefícios
          </h3>
          <p v-scrollanimation class="listSquare delay-1 fade-in-bottom">
            <ul class="list square benefits">
              <li class="item">
                 Automação do processo de cobrança interno dos clientes inadimplentes.
              </li>
              <li class="item">
                 Maior eficiência na recuperação da receita.
              </li>
            </ul>
          </p>
      </div>
    </section>
    <section class="section about clients">
      <div class="container">
        <h3 v-scrollanimation class="title_section delay-1 fade-in-bottom">
          Sobre a vivo
        </h3>
        <div class="content d_flex">
          <img
            v-scrollanimation
            src="../../assets/img/estudos_de_caso/vivo_cobranca/vivo_empresa.jpg"
            alt="Vivo empresa"
            class="company delay-2 fade-in-bottom"
          />
        <div class="text">
          <p v-scrollanimation class="company delay-2 fade-in-bottom">
            A Vivo existe desde 2003 no Brasil, sendo, dois anos depois, Top of Mind no mercado de telefonia celular e em 2008 atingiu o marco histórico de 40 milhões de clientes. Em 2010 entrou para o grupo Telefônica, um dos líderes mundiais de telecomunicações e consolidou a liderança levando o sinal 3G para os quatro cantos do país.
          </p>
          <p v-scrollanimation class="company delay-2 fade-in-bottom">
            Em 2013 lançou a tecnologia 4g e o APP Meu Vivo, melhorando a autonomia do cliente ao gerenciar seus planos pelo celular. Em 2015 ficou entre as 10 marcas mais valiosas do Brasil, pela quinta vezconsecutiva e atualmente disponibiliza serviços de telefonia fixa e móvel, internet, TV e outros serviços digitais como Apps avulsos e Vivo Pay.
          </p>
        </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "CasdeStudyBayer",
  methods: {
    verifyPosNumbers() {
      const numbers = [
        this.$refs.number1,
        this.$refs.number2,
        this.$refs.number3,
        this.$refs.number4,
        this.$refs.number5,
        this.$refs.number6,
        this.$refs.number7,
        this.$refs.number8,
        this.$refs.number9,
        this.$refs.number10,
        this.$refs.number11,
        this.$refs.number12,
        this.$refs.number13,
        this.$refs.number14,
        this.$refs.number15,
        this.$refs.number16,
        this.$refs.number17,
        this.$refs.number18,
        this.$refs.number19,
        this.$refs.number20,
        this.$refs.number21,
        this.$refs.number22,
        this.$refs.number23,
        this.$refs.number24,
      ];
      numbers.forEach((number) => {
        number.$el.classList.contains("enter") ? number.play() : false;
      });
    },
  },
  created() {
    this.verifyPosNumbers;
    window.addEventListener("scroll", this.verifyPosNumbers);
  },
  destroyed() {
    window.removeEventListener("scroll", this.verifyPosNumbers);
  },
};
</script>

<style lang="scss" scoped>
#caseStudyVivoCobranca {
  .about,
  .why {
    background-color: $DarkGrey;

    .title_section {
      &.company {
        text-align: left;
        margin-bottom: 0;
      }
    }

    .content {
      align-items: initial;

      @include respond(sm1-up) {
        display: inline-block;
      }
    }

    .text {
      width: 70%;

      @include respond(sm1-up) {
        width: 100%;
      }

      p {
        &.company {
          width: 100%;
          margin: -43px 22px;
          padding: 39px;

          @include respond(sm1-up) {
            padding: 20px 0;
            margin: 0 auto;
          }
        }
      }
    }

    img {
      width: 30%;

      @include respond(sm1-up) {
        width: 100%;
      }

      &.company {
        width: 45%;
        filter: saturate(0);

        &:hover {
          filter: saturate(1);
          transition: all 0.3s ease;
        }

        @include respond(sm1-up) {
          width: 100%;
        }
      }
    }
  }

  .header {
    background: url(../../assets/img/estudos_de_caso/header_estudos_de_caso.jpg)
      no-repeat center !important;
    background-size: cover !important;
  }

  .cobrancaProject {
    @include respond(sm1-up) {
      display: inline-block;
    }
  }

  .challenges {
    background-color: $DarkGreySecondary;

    .challengesList {
      @include respond(sm2-up) {
        margin: 0 auto;
      }

      @include respond(md-up) {
        margin: 0 243px;
      }
    }
  }

  .clients {
    background-color: $DarkGreySecondary;

    .container {
      @include respond(md-up) {
        padding: 80px 30px;
      }
    }
  }

  .gallery {
    background-color: $DarkGrey;
  }

  .images {
    background-color: $DarkGrey;
  }

  .results {
    @include respond(sm1-up) {
      margin: 0 auto;
    }

    img {
      &.result.sms {
        max-width: 160px;

        @include respond(md-up) {
          max-width: 160px !important;
        }

        @include respond(sm2-up) {
          max-width: 125px;
        }
      }

      @include respond(md-up) {
        max-width: 120px !important;
      }

      @include respond(sm2-up) {
        max-width: 100px;
      }

      @include respond(sm1-up) {
        max-width: 100px !important;
      }
    }

    .title_section {
      &.table {
        color: $TitleColor;
        margin-top: 110px;

        @include respond(md-up) {
          font-size: 30px !important;
        }

        @include respond(sm2-up) {
          font-size: 20px;
        }
      }
    }

    .table {
      width: 68%;
      margin: 15px auto;
      border-collapse: collapse;

      td {
        border: 0.5px solid #fff;

        &.info {
          @include respond(md-up) {
            font-size: 20px !important;
          }

          @include respond(sm2-up) {
            font-size: 15px;
          }

          @include respond(sm1-up) {
            font-size: 12px;
          }
        }

        &.infos {
          @include respond(sm1-up) {
            font-size: 12px;
          }

          @include respond(md-up) {
            font-size: 30px;
          }
        }
      }
    }

    .dice {
      width: calc(33.3%);
      float: left;
      margin-bottom: 100px;

      @include respond(sm1-up) {
        width: auto;
        margin: 0 100px;
        flex-direction: column;
        float: none;
      }

      h3 {
        text-align: center;
        margin-bottom: 20px;
      }

      p {
        @include respond(md-up) {
          &:first-child {
            padding-right: 0px !important;
          }

          &:last-child {
            padding-left: 0px !important;
          }
        }
      }

      .result {
        margin-top: 60px;
      }
    }

    ul,
    .listSquare {
      text-align: center;
      display: block;
      padding-right: 5px;
    }
  }
  .history {
    background-color: $DarkGrey;

    .text {
      text-align: center;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 50px;

      @include respond(sm1-up) {
        width: auto;
        margin-right: 0;
        flex-direction: column;
        float: none;
      }
    }

    .text2 {
      flex-direction: column;
      text-align: center;
      font-size: 27px;
      font-weight: bold;
      margin-bottom: -10px;

      @include respond(sm1-up) {
        width: auto;
        margin-right: 0;
        flex-direction: column;
        float: none;
      }
    }

    h3 {
      margin-bottom: 20px;

      &.title {
        margin: 30px 0 0;
      }

      &.section {
        margin-top: 90px;
      }
    }

    .benefits {
      text-align: center;
    }
  }
}
</style>