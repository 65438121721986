<template>
  <div class="item card">
    <a class="link" :href="data.route" :title="`Ir para ${data.title}`">
      <i :class="'icon ' + data.icon"></i>
      <img class="img" :src="data.img" :alt="data.alt" :width="data.width" :height="data.height" />
      <h4 class="title">{{ data.title }}</h4>
      <small class="subtitle">{{ data.subtitle }}</small>
      <p>{{ data.text }}</p>
    </a>
  </div>
</template>

<script>
export default {
  name: "ProductCard",
  props: {
    data: {
      icon: String,
      img: String,
      title: String,
      subtitle: String,
      text: String,
      route: String,
    },
  },
};
</script>


<style lang="scss" scoped>
.item {
  padding: 20px;
  background: $GradientBlackBottom;
  border-radius: 10px;
  overflow: hidden;
  opacity: 0;
  @include transition(all 0.3s linear);

  @include respond(md-up) {
    flex-basis: 33.33%;
  }

  i.icon {
    display: block;
    margin: 9px auto;
    font-size: 60px;
    @include transform(scale(0.9));
    @include transition(all 0.3s linear);

    @include respond(md-up) {
      font-size: 100px;
    }
  }

  .img {
    display: block;
    margin: 0 auto 70px;
    @include transform(scale(0.9));
    @include transition(all 0.3s linear);
    filter: grayscale(100%);
    max-width: 110px;

    @include respond(md-up) {
      max-width: 110px !important;
    }

    @include respond(sm2-up) {
      max-width: 70px;
    }

    @include respond(sm1-up) {
      max-width: 70px;
    }
  }

  .title {
    margin-bottom: 0 !important;
  }

  .subtitle {
    font-size: 14px;
    &::after {
      content: "";
      display: block;
      width: 60px;
      height: 6px;
      margin: 30px auto;
      background-color: $PrimaryColor;
      border-radius: 3px;
    }
  }

  &:hover {
    background: $GradientGreyBottom;
    color: $White;

    i.icon {
      color: $PrimaryColor;
      @include transform(scale(1));
    }

    .img {
      filter: grayscale(0%);
      @include transform(scale(1));
    }

    .title,
    .subtitle {
      color: $White;
    }

    .subtitle::after {
      background-color: $White;
    }
  }
}
</style>