import Vue from 'vue';
import App from './App.vue';
import './assets/scss/global.scss';
import * as mobileMixin from './mixins/global.js';
import VueRouter from 'vue-router';
import { routes } from './routes';
import store from './store';
import ScrollAnimation from './directives/scrollanimation';
import VueNumber from 'vue-number-animation';

// Plugin Carrossel
import VueSplide from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';


// Diretiva animação no scroll
Vue.directive('scrollanimation', ScrollAnimation);
Vue.config.productionTip = false;

// Libs de Efeito
Vue.use(VueSplide);
Vue.use(VueNumber);

// Aceite Cookies
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

// Config de Rotas
Vue.use(VueRouter);
const router = new VueRouter({
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes,
  mode: 'history',
});

new Vue({
  router,
  store,
  mixins: [mobileMixin],
  render: h => h(App)
}).$mount('#app')
