<template>
  <main id="contact">
    <header class="header">
      <div class="container">
        <h1 v-scrollanimation class="title delay-1 fade-in-bottom">Contato</h1>
        <p v-scrollanimation class="delay-2 fade-in-bottom">
          Fale conosco para um atendimento exclusivo e personalizado.
        </p>
      </div>
    </header>
    <section class="section message">
      <div class="container">
        <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">
          Mande uma mensagem
        </h3>
        <p v-scrollanimation class="delay-3 fade-in-bottom">
          Peça uma demonstração direcionada para seu negócio, entre em contato
          para criarmos a evolução na comunicação digital da sua empresa!
        </p>
        <form
          id="envia_email"
          class="form"
          action="#"
          method="POST"
          v-on:submit.prevent="enviaEmail"
        >
          <div class="content d_flex">
            <Input
              v-scrollanimation
              class="delay-4 fade-in-bottom"
              :type="'text'"
              :name="'fullname'"
              :label="fullname.label"
              :errorMessage="
                fullname.hasError ? 'Por favor, digite seu nome completo.' : ''
              "
              @setValue="validateName($event)"
              :isValidated="fullname.isValidated"
            />
            <Input
              v-scrollanimation
              class="delay-4 fade-in-bottom"
              :type="'email'"
              :name="'email'"
              :label="email.label"
              :errorMessage="email.hasError ? 'Informe um e-mail válido.' : ''"
              @setValue="validateEmail($event)"
              :isValidated="email.isValidated"
            />
            <Input
              v-scrollanimation
              class="delay-4 fade-in-bottom"
              :type="'text'"
              :name="'phone'"
              v-mask="'(##) #####-####'"
              :label="phone.label"
              :errorMessage="
                phone.hasError ? 'Digite um número de telefone válido.' : ''
              "
              @setValue="validatePhone($event)"
              :isValidated="phone.isValidated"
            />
            <Input
              v-scrollanimation
              class="delay-4 fade-in-bottom"
              :type="'text'"
              :name="'subject'"
              :label="subject.label"
              :errorMessage="
                subject.hasError ? 'Insira um assunto para sua mensagem.' : ''
              "
              @setValue="validateSubject($event)"
              :isValidated="subject.isValidated"
            />
            <Textarea
              v-scrollanimation
              class="delay-4 fade-in-bottom"
              :name="'message'"
              :label="message.label"
              :errorMessage="
                message.hasError ? 'Diga-nos o motivo do seu contato.' : ''
              "
              @setValue="validateMessage($event)"
              :isValidated="message.isValidated"
            />
            <Button
              :addClass="'delay-5'"
              :id="'button_send'"
              :type="'submit'"
              :label="'Enviar'"
              :isDisabled="disableButton"
            />
          </div>
        </form>
        <p v-html="dataReturn"></p>
      </div>
    </section>
    <section class="section talk-now">
      <div class="container">
        <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">
          Fale agora
        </h3>
        <p v-scrollanimation class="delay-3 fade-in-bottom">
          Deseja um atendimento mais rápido e prático? Envie uma mensagem para o
          nosso WhatsApp ou ligue para nós.
        </p>
        <div class="actions d_flex">
          <a
            v-scrollanimation
            class="button whats delay-1 scale-in-center"
            href="https://api.whatsapp.com/send?phone=5511969117556&text=Ola!%20Gostaria%20de%20mais%20informacoes%20sobre%20os%20produtos%20da%20DataCore%20Solutions!"
            title="Mande uma mensagem por WhatsApp"
            target="_blank"
          >
            <span><i class="icon icon-whatsapp"></i>WhatsApp</span>
          </a>
          <a
            v-scrollanimation
            class="button phone delay-2 scale-in-center"
            href="tel:+5511969117556"
            title="Ligue agora e converse conosco"
          >
            <span><i class="icon icon-phone"></i>Telefone</span>
          </a>
        </div>
      </div>
    </section>
    <section class="section map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20575.260505643884!2d-46.72711265332194!3d-23.62473501382548!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce50dc5bbcedab%3A0x853e50aa504809b!2sAv.%20Dr.%20Chucri%20Zaidan%2C%201550%20-%20Vila%20Cordeiro%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2004583-110!5e0!3m2!1spt-BR!2sbr!4v1620789400115!5m2!1spt-BR!2sbr"
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </section>
  </main>
</template>


<script>
import Button from "../components/common/Button.vue";
import Input from "../components/common/Input.vue";
import Textarea from "../components/common/Textarea.vue";
import axios from "axios";
import Vue from 'vue';
import VueMask from 'v-mask';

Vue.use(VueMask);

Vue.config.productionTip = false;

export default {
  name: "Contact",
  components: {
    Input,
    Textarea,
    Button,
  },
  data() {
    return {
      dataReturn: "",
      disableButton: true,
      fullname: {
        data: "",
        label: "Nome completo",
        isValidated: false,
        hasError: false,
      },
      email: {
        data: "",
        label: "E-mail",
        isValidated: false,
        hasError: false,
      },
      phone: {
        data: "",
        label: "Número de telefone",
        isValidated: false,
        hasError: false,
      },
      subject: {
        data: "",
        label: "Assunto",
        isValidated: false,
        hasError: false,
      },
      message: {
        data: "",
        label: "Mensagem",
        isValidated: false,
        hasError: false,
      },
    };
  },
  methods: {
    enviaEmail() {
      const formEnvia = {
        fullname: this.fullname.data,
        email: this.email.data,
        phone: this.phone.data,
        subject: this.subject.data,
        message: this.message.data,
      };

      this.fullname.isValidated = false;
      this.email.isValidated = false;
      this.phone.isValidated = false;
      this.subject.isValidated = false;
      this.message.isValidated = false;

      this.validateForm();

      this.dataReturn = "Enviando...";

      /**
       * URL PROD: /mailer/send.php
       * URL HML.: http://localhost:8888/send.php
       */
      axios
        .post("/mailer/send.php", formEnvia)
        .then((data) => {
          this.dataReturn = data.data.message;
        })
        .catch((error) => {
          this.dataReturn =
            "Não foi possível enviar seu email no momento, tente novamente mais tarde.";
          console.error(error);
        })
        .finally(() => {
          document.querySelector("form").reset();
        });
    },
    validateForm() {
      const fields = ["fullname", "phone", "email", "subject", "message"];
      const invalid = fields.find((e) => {
        const field = this[e];
        return !field.isValidated || field.hasError;
      });
      if (invalid) {
        this.disableButton = true;
      } else {
        this.disableButton = false;
      }
    },
    validateName(val) {
      this.fullname.isValidated = true;
      const regex = /^(([a-z])+\s)([a-z])+(\s([a-z])*)?/i;
      if (val !== "" && regex.test(val)) {
        this.fullname.data = val;
        this.fullname.hasError = false;
      } else {
        this.fullname.hasError = true;
      }

      this.validateForm();
    },
    validateEmail(val) {
      this.email.isValidated = true;
      const regex = /\S+@\S+\.\S+/;
      if (val !== "" && regex.test(val)) {
        this.email.data = val;
        this.email.hasError = false;
      } else {
        this.email.hasError = true;
      }
      this.validateForm();
    },
      validatePhone(val) {
        this.phone.isValidated = true;
        const regex =
          /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/;

        if (val !== "" && regex.test(val)) {
          this.phone.data = val;
          this.phone.hasError = false;
        } else {
          this.phone.hasError = true;
        }
        this.validateForm();
      },
    validateSubject(val) {
      this.subject.isValidated = true;
      if (val !== "") {
        this.subject.data = val;
        this.subject.hasError = false;
      } else {
        this.subject.hasError = true;
      }
      this.validateForm();
    },
    validateMessage(val) {
      this.message.isValidated = true;
      if (val !== "") {
        this.message.data = val;
        this.message.hasError = false;
      } else {
        this.message.hasError = true;
      }
      this.validateForm();
    },
  },
};
</script>

<style lang="scss" scoped>
#contact {
  .header {
    background-image: url('../assets/img/bg-contato.webp') !important;
  }

  .title_section {
    & + p {
      max-width: 600px;
      margin: 0 auto 40px;
    }
  }

  .message {
    .form {
      .content {
        flex-wrap: wrap;

        @include respond(sm2-up) {
          max-width: 840px;
          margin: 0 auto;
        }

        @include respond(md-up) {
          max-width: 1000px;
        }
      }
    }
  }

  .talk-now {
    background-color: $DarkGreySecondary;

    .actions {
      margin: 0 -14px;

      .button {
        display: inline-block;
        min-width: 100px;
        margin: 0 10px;
        padding: 0 20px;
        font-weight: bold;
        line-height: 40px;
        text-align: center;
        font-size: 14px;

        @include border-radius(5px);
        @include box-shadow(0 4px 4px rgba(0, 0, 0, 0.15));
        @include transition(all 0.3s linear);

        &.whats {
          background-color: #06d755;
          color: $White;

          &:hover {
            background-color: $White;
            color: #06d755;
          }
        }

        &.phone {
          background-color: $PrimaryColor;
          color: $White;

          &:hover {
            background-color: $White;
            color: $PrimaryColor;
          }
        }

        span {
          display: inline-block;
        }

        i {
          float: left;
          margin-right: 5px;
          font-size: 16px;
          line-height: 40px;
        }
      }
    }
  }

  .map {
    iframe {
      display: block;
      width: 100%;
      height: 200px;
      border: 0 none;

      @include respond(md-up) {
        height: 400px;
      }
    }
  }
}
</style>