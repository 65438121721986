<template>
  <div id="cookies" :class="cookieAccepted ? '' : 'active'">
    <div class="container d_flex">
      <p>
        Esse site utiliza cookies para personalizar sua navegação e trazer conteúdos relevantes de seu interesse. Portanto ao utilizar esse site, você concorda com nossos <router-link to="/privacidade">Termos de Uso e Condições</router-link>.
      </p>
      <Button
        @clickButton="acceptPrivacy()"
        :addClass="'cookie'"
        :route="''"
        :label="'Aceitar'"/>
    </div>
  </div>
</template>

<script>
import Button from './common/Button.vue';

export default {
  name: 'Cookies',
  components: { Button },
  data() {
    return {
      cookieAccepted: false,
    };
  },
  methods: {
    createPrivacy() {
      const existsPrivacy = this.$cookies.isKey('privacyAccepted');
      if (!existsPrivacy) {
        this.$cookies.set('privacyAccepted', '');
      }

      this.getPrivacy();
    },
    acceptPrivacy() {
      this.$cookies.set('privacyAccepted', 'Accepted', '7d');
      this.cookieAccepted = true;
    },
    getPrivacy() {
      const privacyValue = this.$cookies.get('privacyAccepted');
      if( privacyValue !== '' && privacyValue !== null ) {
        this.cookieAccepted = true;
      } else {
        this.cookieAccepted = false;
      }
    },
    deletePrivacy() {
      this.$cookies.delete('privacyAccepted');
      this.cookieAccepted = false;
    }
  },
  created() {
    window.addEventListener('load', this.createPrivacy());
  },
};
</script>

<style lang="scss" scoped>
  #cookies {
    position: fixed;
    left: 50%;
    bottom: 30px;
    width: 50%;
    opacity: 0;
    pointer-events: none;
    z-index: 100;
    @include transform(translate(-50%, 100%));
    @include transition(all .3s ease-in);

    &.active {
      opacity: 1;
      pointer-events: auto;
      @include transform(translate(-50%, 0%));
    }

    .container {
      flex-wrap: wrap;
      padding: 20px 30px;
      background-color: $White;
      @include border-radius(5px);
      @include box-shadow(5px 5px 15px rgba(0,0,0,.2));
      
      @include respond(sm2-up) {
        flex-wrap: nowrap;
      }

      p {
        margin: 0 0 20px;
        color: #000;
        font-size: 12px;
        @include respond(sm2-up) {
          margin-bottom: 0;
          padding-right: 40px;
        }
      }

      a {
        color: $PrimaryColor;
      }
    }
  }
</style>