<template>
  <div id="splash" :class="{ loader: true, fadeout: !isLoading }">
    <div class="center">
        <div class="effect">
            <img class="symbol" src="./../assets/img/logo-datacoress-simbolo.webp" alt="Símbolo DataCore" width="105" height="151">
        </div>
        <img class="logo" src="./../assets/svg/logo_datacore.svg" alt="Logo DataCore" width="500" height="35">
        <img class="legend" src="./../assets/svg/word_solutions.svg" alt="Solutions" width="500" height="21">
    </div>
</div>
</template>

<script>

export default {
  name: 'Splash',
  props: ["isLoading"],
}
</script>

<style lang="scss" scoped>
  #splash {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #1a1a1a;
    z-index: 1000;
    .center {
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
      transform: scale(.6);
      @include respond(md-up) {
        transform: scale(.7);
      }
      @include respond(lg-up) {
        transform: scale(1);
      }
      .symbol {
        max-width: 100px;
        margin: 0 auto;
        opacity: 0;
      }
      .logo {
        margin: 40px auto 20px;
        opacity: 0;
      }
      img {
        -webkit-animation: fadeIn 1s 1 .5s;
        -moz-animation: fadeIn 1s 1 .5s;
        -o-animation: fadeIn 1s 1 .5s;
        animation: fadeIn 1s 1 .5s;
        animation-fill-mode: forwards;
        &.logo {
          -webkit-animation-delay: .7s;
          -moz-animation-delay: .7s;
          -o-animation-delay: .7s;
          animation-delay: .7s;
        }
      }
      .legend {
        width: 100%;
        opacity: 0;
        transition: all .3s ease-in;

        -webkit-animation: scale-letter 2s 1 1s;
        -moz-animation: scale-letter 2s 1 1s;
        -o-animation: scale-letter 2s 1 1s;
        animation: scale-letter 2s 1 1s;
        animation-fill-mode: forwards;
      }
    }
  }
  .fadeout {
    animation: fadeout 2s forwards;
  }
</style>
