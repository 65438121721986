import Home from './views/Home';
import About from './views/About';
import Services from './views/Services';
import Solutions from './views/Solutions';
import CaseStudy from './views/CaseStudy';
import CaseStudyVivoOnline from './views/cases/CaseStudyVivoOnline';
import CaseStudyBayer from './views/cases/CaseStudyBayer';
import CaseStudyVivoCobranca from './views/cases/CaseStudyVivoCobranca';
import Products from './views/Products';
import VideoInteractive from './views/products/VideoInteractive';
import Chatbot from './views/products/Chatbot';
import MailSMS from './views/products/MailSMS';
import UniqueTrainer from './views/products/UniqueTrainer';
import SingleRepository from './views/products/SingleRepository';
import DataIntegrator from './views/products/DataIntegrator';
import Contact from './views/Contact';
import Privacy from './views/Privacy';
import NotFound from './views/NotFound';

export const routes = [
  {
    path: '/produtos',
    name: 'Produtos',
    slug: 'produtos',
    menu: true,
    component: Products,
    children: [
      {
        path: 'video-interativo',
        name: 'Video Interativo',
        slug: 'video-interativo',
        menu: true,
        component: VideoInteractive,
      },
      {
        path: 'chatbot',
        name: 'Chatbot',
        slug: 'chatbot',
        menu: true,
        component: Chatbot,
      },
      {
        path: 'gerenciador-de-comunicacoes',
        name: 'CCM',
        slug: 'gerenciador-de-comunicacoes',
        menu: true,
        component: MailSMS,
      },
      {
        path: 'formatador-unico',
        name: 'Formatador Único',
        slug: 'formatador-unico',
        menu: true,
        component: UniqueTrainer,
      },
      {
        path: 'repositorio-unico',
        name: 'Repositório Único',
        slug: 'repositorio-unico',
        menu: true,
        component: SingleRepository,
      },
      {
        path: 'integrador-de-dados',
        name: 'Integrador de Dados',
        slug: 'integrador-de-dados',
        menu: true,
        component: DataIntegrator,
      },
    ],
  },
  {
    path: '/solucoes',
    name: 'Soluções',
    slug: 'solucoes',
    menu: true,
    component: Solutions,
  },
  {
    path: '/servicos',
    name: 'Serviços',
    slug: 'servicos',
    menu: true,
    component: Services,
  },
  {
    path: '/estudos-de-caso',
    name: 'Estudos de Caso',
    slug: 'estudos-de-caso',
    menu: true,
    component: CaseStudy,
  },
  {
    path: '/sobre',
    name: 'Sobre',
    slug: 'sobre',
    menu: true,
    component: About,
  },
  {
    path: '/contato',
    name: 'Contato',
    slug: 'contato',
    menu: true,
    component: Contact,
  },

  // Routers que não constam na lista da home //

  {
    path: '/',
    name: 'Home',
    slug: 'home',
    menu: false,
    component: Home,
  },
  {
    path: '/index.html',
    name: 'Home',
    slug: 'home',
    menu: false,
    component: Home,
  },
  {
    path: '/estudos-de-casos-vivo-online',
    name: 'Estudos de Casos Vivo Online',
    slug: 'estudos-de-casos-vivo-online',
    menu: false,
    component: CaseStudyVivoOnline,
  },
  {
    path: '/estudos-de-casos-bayer',
    name: 'Estudos de Casos Bayer',
    slug: 'estudos-de-casos-bayer',
    menu: false,
    component: CaseStudyBayer,
  },
  {
    path: '/estudos-de-casos-vivo-cobranca',
    name: 'Estudos de Casos Vivo Cobrança',
    slug: 'estudos-de-casos-vivo-cobranca',
    menu: false,
    component: CaseStudyVivoCobranca,
  },
  {
    path: '/privacidade',
    name: 'Privacidade',
    slug: 'privacidade',
    menu: false,
    component: Privacy,
  },

  // E-Book Representante Digital
  {
    path: '/ebook/representante_digital/',
    beforeEnter() {location.href = 'https://datacoress.com/landingpages/ebook/representante_digital/ebook.html'}
  },

  // 404
  {
    path: '/*',
    name: '404',
    slug: '404',
    menu: false,
    component: NotFound,
  },
];