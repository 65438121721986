<template>
  <main id="caseStudyVivoOnline">
    <header class="header">
      <div class="container">
        <h1 v-scrollanimation class="title delay-1 fade-in-bottom">
          Estudos de Caso
        </h1>
        <p v-scrollanimation class="delay-2 fade-in-bottom">
          Conheça como empresas estão revolucionando a <br>experiência do cliente com soluções da Data Core Solutions.
        </p>
      </div>
    </header>
    <section class="section about">
      <div class="container">
        <h3 v-scrollanimation class="title_section company delay-1 fade-in-bottom">
          Projeto Fatura Online
        </h3>
        <div class="onlineProject d_flex">
        <div class="text">
          <p v-scrollanimation class="text delay-2 fade-in-bottom">
            Saiba como a Vivo cria, dispara e armazena milhões de faturas, através de diversos canais, com agilidade e eficiência.
          </p>
        </div>
          <img
            v-scrollanimation
            src="../../assets/svg/icon_vivo.svg"
            alt="Icon Vivo"
            class="icon empresas delay-2 fade-in-bottom"
          />
        </div>
      </div>
    </section>
    <section class="section challenges">
      <div class="container">
        <h3 v-scrollanimation class="title_section delay-1 fade-in-bottom">
          Desafios
        </h3>
         <p v-scrollanimation class="listSquare delay-1 fade-in-bottom">
            <ul class="list square">
              <li class="item">
                 Proporcionar fatura impressa e versão online detalhada.
              </li>
              <li class="item">
                 Processar 25 milhões de faturas por mês em 7 ciclos de 3,5 milhões cada.
              </li>
            </ul>
          </p>
      </div>
    </section>
    <section  v-scrollanimation class="section results delay-2 fade-in-bottom">
      <div class="container">
          <h3 class="title_section ">
            Resultados
          </h3>
        <div class="dice">
         <img v-scrollanimation class="icons-text delay-1 fade-in-bottom" src="../../assets/img/estudos_de_caso/vivo_online/icon_processamento_geracao_da_fatura.png" alt="Icon processamento da fatura">
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            Processamento mais eficiente na geração das faturas tanto em versão física quanto online.
          </p>
        </div>
        <div class="dice">
         <img v-scrollanimation class="icons-text delay-1 fade-in-bottom" src="../../assets/img/estudos_de_caso/vivo_online/icon_entrega_eficiente.png" alt="Icon entrega eficiente">
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            Processamento mais eficiente na geração das faturas tanto em versão física quanto online.
          </p>
      </div>
       <div class="dice">
         <h3 v-scrollanimation class="title_section delay-1 fade-in-bottom">
          +<number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number1"
                :from="0"
                :to="25"
                :duration="7"
                easing="Power1.easeOut"/>
                milhões
        </h3>
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            de faturas processadas <br>por mês
          </p>
        </div>
        <div class="dice">
         <h3 v-scrollanimation class="title_section delay-1 fade-in-bottom">
          +<number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number2"
                :from="0"
                :to="300"
                :duration="7"
                easing="Power1.easeOut"/>
                milhões
        </h3>
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            de faturas processadas <br>e armazenadas por ano
          </p>
        </div>
        <div class="dice">
         <h3 v-scrollanimation class="title_section delay-1 fade-in-bottom">
          +<number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number3"
                :from="1"
                :to="1"
                :duration="7"
                easing="Power1.easeOut"/>.<number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number4"
                :from="0"
                :to="5"
                :duration="7"
                easing="Power1.easeOut"/>
                bilhão
        </h3>
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            de páginas processadas <br>e armazenadas por ano
          </p>
        </div>
        <div class="dice">
         <h3 v-scrollanimation class="title_section delay-1 fade-in-bottom">
          +<number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number5"
                :from="0"
                :to="50"
                :duration="7"
                easing="Power1.easeOut"/>
                milhões
        </h3>
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            de e-mails processados <br>por mês com boleto em anexo
          </p>
        </div>
        <div class="dice sms">
         <h3 v-scrollanimation class="title_section title results medicine delay-1 fade-in-bottom">
          +<number
                v-scrollanimation
                class="delay-1 fade-in-bottom"  
                animationPaused
                ref="number6"
                :from="0"
                :to="50"
                :duration="7"
                easing="Power1.easeOut"/>
                milhões
        </h3>
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            de SMS processados <br>por mês com envio do código de barras
          </p>
        </div>
      </div>
    </section>
    <section class="images">
      <ul class="list d_flex">
        <li class="item">
          <img
            v-scrollanimation
            class="delay-1 scale-in-center"
            src="../../assets/img/estudos_de_caso/vivo_online/empresa_vivo_faturas_envio_clientes.jpg"
            alt="Homem escrevendo em uma folha de papel"
          />
        </li>
        <li class="item">
          <img
            v-scrollanimation
            class="delay-2 scale-in-center"
            src="../../assets/img/estudos_de_caso/vivo_online/empresa_vivo_faturas_envio_clientes2.jpg"
            alt="Mulher lendo uma folha de papel"
          />
        </li>
        <li class="item">
          <img
            v-scrollanimation
            class="delay-3 scale-in-center"
            src="../../assets/img/estudos_de_caso/vivo_online/empresa_vivo_faturas_envio_clientes3.jpg"
            alt="Mulher mexendo no celular"
          />
        </li>
      </ul>
    </section>
    <section class="section history">
      <div class="container">
          <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">
            História
          </h3>
        <div v-scrollanimation class="text d_flex delay-2 fade-in-bottom">
          <p>
            O Fundador e CEO da Data Core Solutions, Manuel Alejandro Reyes Bustos, iniciou a implementação do projeto na empresa Vivo, parceria já de longa data. Isso ocorreu devido a confiança construída especialmente pela qualidade do suporte prestado pela Data Core ao projeto.
          </p>
          <p>
            Para formatar o design, a Data Core criou seções dinâmicas na fatura em ciclos, permitindo atualização a cada ciclo, com uma mensagem importante ao cliente. E as comunicações de email e SMS para notificar o cliente de uma nova fatura gerada.
          </p>
          <p>
            Outra parte do processo é o Repositório Único, que foi implementado e comprime 90% do tamanho da imagem gerada da fatura, indexa os arquivos para permitir o armazenamento correto por até 10 anos e permite a recuperação por API para qualquer canal de atendimento e autoatendimento. E o Disparador de Comunicações - para enviar  email e SMS ao cliente, demonstrando através de relatórios a efetividade de processamento e entrega das mensagens.
          </p>
          <p>
            Mas afinal, quais foram as grandes mudanças do projeto para a Vivo?
          </p>
          </div>
          <div v-scrollanimation class="text2 d_flex delay-2 fade-in-bottom">
          <p>
            “Facilitou muito para a Vivo concentrar em só um lugar, todas as informações da fatura.
          </p>
          <p>
            Antigamente as informações eram armazenadas em billings separados e para receber esses dados, havia a necessidade de abrir chamado para o departamento de TI e o processo era muito longo.
          </p>
          <p>
            A centralização das informações com as soluções da Data Core foi o grande diferencial para mais agilidade e excelência na entrega das faturas aos clientes.”
          </p>
          </div>
          <p>
            Fundador e CEO da Data Core Solutions, Manuel Alejandro Reyes Bustos
          </p>
          <h3 v-scrollanimation class="title_section section delay-2 fade-in-bottom">
            Benefícios
          </h3>
          <p v-scrollanimation class="listSquare delay-1 fade-in-bottom">
            <ul class="list square">
              <li class="item">
                 Mais agilidade e qualidade na entrega das faturas aos clientes em diversos canais.
              </li>
              <li class="item">
                 Excelência no suporte prestado com profissionais altamente qualificados.
              </li>
            </ul>
          </p>
      </div>
    </section>
    <section class="section about clients">
      <div class="container">
        <h3 v-scrollanimation class="title_section delay-1 fade-in-bottom">
          Sobre a vivo
        </h3>
        <div class="content d_flex">
          <img
            v-scrollanimation
            src="../../assets/img/estudos_de_caso/vivo_online/vivo_empresa.jpg"
            alt="Vivo empresa"
            class="company delay-2 fade-in-bottom"
          />
        <div class="text">
          <p v-scrollanimation class="company delay-2 fade-in-bottom">
            A Vivo existe desde 2003 no Brasil, sendo, dois anos depois, Top of Mind no mercado de telefonia celular e em 2008 atingiu o marco histórico de 40 milhões de clientes. Em 2010 entrou para o grupo Telefônica, um dos líderes mundiais de telecomunicações e consolidou a liderança levando o sinal 3G para os quatro cantos do país.
          </p>
          <p v-scrollanimation class="company delay-2 fade-in-bottom">
            Em 2013 lançou a tecnologia 4g e o APP Meu Vivo, melhorando a autonomia do cliente ao gerenciar seus planos pelo celular. Em 2015 ficou entre as 10 marcas mais valiosas do Brasil, pela quinta vezconsecutiva e atualmente disponibiliza serviços de telefonia fixa e móvel, internet, TV e outros serviços digitais como Apps avulsos e Vivo Pay.
          </p>
        </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "CasdeStudyVivoOnline",
  methods: {
    verifyPosNumbers() {
      const numbers = [
        this.$refs.number1,
        this.$refs.number2,
        this.$refs.number3,
        this.$refs.number4,
        this.$refs.number5,
        this.$refs.number6,
      ];
      numbers.forEach((number) => {
        number.$el.classList.contains("enter") ? number.play() : false;
      });
    },
  },
  created() {
    this.verifyPosNumbers;
    window.addEventListener("scroll", this.verifyPosNumbers);
  },
  destroyed() {
    window.removeEventListener("scroll", this.verifyPosNumbers);
  },
};
</script>

<style lang="scss" scoped>
#caseStudyVivoOnline {
  .about,
  .why {
    background-color: $DarkGrey;

    .title_section {
      &.company {
        text-align: left;
        margin-bottom: 0;
      }
    }

    .content {
      align-items: initial;

      @include respond(sm1-up) {
        display: inline-block;
      }
    }

    .text {
      width: 70%;

      @include respond(sm1-up) {
        width: 100%;
      }

      p {
        @include respond(md-up) {
          &:first-child {
            padding-right: 45px;
          }
        }

        &.company {
          width: 100%;
          margin: -43px 22px;
          padding: 39px;

          @include respond(sm1-up) {
            padding: 20px 0;
            margin: 0 auto;
          }
        }
      }
    }

    img {
      width: 30%;

      &.company {
        width: 45%;
        filter: saturate(0);

        &:hover {
          filter: saturate(1);
          transition: all 0.3s ease;
        }

        @include respond(sm1-up) {
          width: 100%;
        }
      }
    }

    .onlineProject {
      @include respond(sm1-up) {
        display: inline-block;
      }
    }
  }

  .header {
    background: url(../../assets/img/estudos_de_caso/header_estudos_de_caso.jpg)
      no-repeat center !important;
    background-size: cover !important;
  }

  .challenges {
    background-color: $DarkGreySecondary;
  }

  .clients {
    background-color: $DarkGreySecondary;

    .container {
      @include respond(md-up) {
        padding: 80px 30px;
      }
    }
  }

  .gallery {
    background-color: $DarkGrey;
  }

  .images {
    background-color: $DarkGrey;
  }

  .results {
    @include respond(sm1-up) {
      margin: 0 auto;
    }

    .dice {
      width: calc(50%);
      float: left;
      margin-bottom: 50px;

      @include respond(sm1-up) {
        width: auto;
        flex-direction: column;
        margin-bottom: 40px;
        float: none;
      }

      &.sms {
        margin-bottom: 100px;
        width: calc(100%);
      }

      h3 {
        text-align: center;
        margin-bottom: 0;
        text-transform: none;
      }

      p {
        margin: 0 100px;

        @include respond(md-up) {
          &:first-child {
            padding-right: 0px !important;
          }
        }

        @include respond(sm1-up) {
          margin: 0;
        }
      }

      img {
        @include respond(sm2-up) {
          max-width: 90px;
        }

        @include respond(sm1-up) {
          max-width: 90px;
        }

        @include respond(md-up) {
          max-width: 115px;
        }
      }
    }

    ul,
    .listSquare {
      text-align: center;
      display: block;
      padding-right: 5px;
    }
  }
  .history {
    background-color: $DarkGrey;

    .text {
      text-align: center;
      flex-direction: column;
      align-items: flex-start;

      margin-bottom: 50px;

      @include respond(sm1-up) {
        width: auto;
        margin-right: 0;
        flex-direction: column;
        float: none;
        padding: 0;
      }

      @include respond(sm1-up) {
        padding: 0;
      }

      @include respond(md-up) {
        padding: 0 165px;
      }
    }

    .text2 {
      flex-direction: column;
      padding: 0 161px;
      text-align: center;
      font-size: 27px;
      font-weight: bold;
      line-height: 1em;

      @include respond(sm1-up) {
        width: auto;
        margin-right: 0;
        flex-direction: column;
        float: none;
        padding: 0;
      }

      @include respond(sm2-up) {
        padding: 0;
      }

      @include respond(md-up) {
        padding: 0 165px;
      }
    }

    ul {
      text-align: center;
    }

    h3 {
      margin-bottom: 20px;

      &.section {
        margin-top: 90px;
      }
    }
  }
}
</style>