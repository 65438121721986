<template>
  <main id="solutions">
    <header class="header">
      <div class="container">
        <h1 v-scrollanimation class="title delay-1 fade-in-bottom">Soluções</h1>
        <p v-scrollanimation class="delay-2 fade-in-bottom">
          Crie comunicações em qualquer meio e a qualquer hora, <br />de forma
          integrada, personalizada e omnichannel.
        </p>
      </div>
    </header>
    <section class="section solution">
      <div class="container">
        <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">
          Ecossistema
        </h3>
        <div v-scrollanimation class="ecossistema delay-2 fade-in-bottom">
          <img src="../assets/img/space.gif" class="space" />
          <a
            href="#ecossistema_centralizacao"
            class="reparticoes_ecossistema centralizacao_de_comunicacoes"
          >
            <img
              src="../assets/img/solucoes/ecossistema/icon_centralizacao_de_comunicacoes.png"
              alt="Centralização de Comunicações"
              class="img hover"
            />
            <img
              src="../assets/img/solucoes/ecossistema/icon_centralizacao_de_comunicacoes_hover.png"
              class="img hover2"
            />
          </a>
          <a
            href="#ecossistema_gerenciamento_de_comunicacoes"
            class="reparticoes_ecossistema gerenciamento_de_comunicacoes"
          >
            <img
              src="../assets/img/solucoes/ecossistema/icon_gerenciamento_de_comunicacoes.png"
              alt="Gerenciamento de Comunicações"
              class="img hover"
            />
            <img
              src="../assets/img/solucoes/ecossistema/icon_gerenciamento_de_comunicacoes_hover.png"
              class="img hover2"
            />
          </a>
          <a
            href="#ecossistema_repositorio_unico"
            class="reparticoes_ecossistema repositorio_unico"
          >
            <img
              src="../assets/img/solucoes/ecossistema/icon_repositorio_unico.png"
              alt="Repositório Único"
              class="img hover"
            />
            <img
              src="../assets/img/solucoes/ecossistema/icon_repositorio_unico_hover.png"
              class="img hover2"
            />
          </a>
          <a
            href="#ecossistema_mensageria"
            class="reparticoes_ecossistema mensageria"
          >
            <img
              src="../assets/img/solucoes/ecossistema/icon_mensageria.png"
              alt="Mensageria"
              class="img hover"
            />
            <img
              src="../assets/img/solucoes/ecossistema/icon_mensageria_hover.png"
              class="img hover2"
            />
          </a>
          <a
            href="#ecossistema_disparo_de_comunicacoes"
            class="reparticoes_ecossistema disparo_de_comunicacoes"
          >
            <img
              src="../assets/img/solucoes/ecossistema/icon_disparo_de_comunicacoes.png"
              alt="Disparo de Comunicações"
              class="img hover"
            />
            <img
              src="../assets/img/solucoes/ecossistema/icon_disparo_de_comunicacoes_hover.png"
              class="img hover2"
            />
          </a>
          <a
            href="#ecossistema_integracao"
            class="reparticoes_ecossistema integrador_de_dados"
          >
            <img
              src="../assets/img/solucoes/ecossistema/icon_integrador_de_dados.png"
              alt="Integrador de Dados"
              class="img hover"
            />
            <img
              src="../assets/img/solucoes/ecossistema/icon_integrador_de_dados_hover.png"
              class="img hover2"
            />
          </a>
          <a
            href="#ecossistema_video_interativo"
            class="reparticoes_ecossistema video_interativo"
          >
            <img
              src="../assets/img/solucoes/ecossistema/icon_video_interativo.png"
              alt="Vídeo Interativo e Personalizado"
              class="img hover"
            />
            <img
              src="../assets/img/solucoes/ecossistema/icon_video_interativo_hover.png"
              class="img hover2"
            />
          </a>

          <img
            v-scrollanimation
            src="../assets/img/logo-datacoress-simbolo.webp"
            alt="Logo símbolo DataCore"
            class="logo-datacore delay-2 fade-in-bottom"
            width="105"
            height="151"
          />
        </div>
      </div>
    </section>
    <section class="section solution" id="ecossistema_centralizacao">
      <div class="container">
        <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">
          Centralização de Comunicações
        </h3>
        <div class="text d_flex">
          <p v-scrollanimation class="delay-1 fade-in-bottom">
            Centralize, padronize e personalize as comunicações multicanal. Esse
            hub de comunicações de alto desempenho usa modelos sofisticados e
            aplicativos para combinar dados de clientes e conteúdo personalizado
            em campanhas multicanais atraentes e econômicas.
          </p>
          <p v-scrollanimation class="delay-1 fade-in-bottom">
            Da criação de documentos ao arquivamento e recuperação, você ganhará
            novas eficiências. Entregue documentos estruturados e interativos
            via produção em lote ou em tempo real.
          </p>
          <ul v-scrollanimation class="list square delay-2 fade-in-bottom">
            <li class="item">Fácil implantação</li>
            <li class="item">Rápida integração</li>
            <li class="item">Aumenta o engajamento</li>
            <li class="item">Aumenta a padronização e controle</li>
          </ul>
          <img
            v-scrollanimation
            src="../assets/svg/solucoes/icon_centralizacao_de_comunicacoes.svg"
            alt="Integração de Dados"
            class="adds delay-2 fade-in-bottom"
          />
        </div>
      </div>
    </section>
    <section
      class="section solution"
      id="ecossistema_gerenciamento_de_comunicacoes"
    >
      <div class="container">
        <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">
          Gerenciamento de Comunicações
        </h3>
        <div class="text d_flex">
          <p v-scrollanimation class="delay-1 fade-in-bottom">
            Os usuários desejam que as empresas estejam prontas para
            compartilhar e se comunicar da maneira que esperam, especialmente
            quando necessitam de algo. E com 85% dos clientes preferindo uma
            mistura de canais, oferecer excelente experiência através dos
            diversos canais de atendimento, deve ser o foco no atendimento ao
            cliente.
          </p>
          <p v-scrollanimation class="delay-1 fade-in-bottom">
            Permite a criação e o disparo de comunicação em diversos canais
            distintos ao mesmo tempo de forma responsiva e escalonável. O
            processo intuitivo da ferramenta, permite que todos os usuários
            consigam utilizá-la de maneira simples, sem conhecimento técnico
            aprofundado, com opção arrasta e solta.
          </p>
          <p v-scrollanimation class="delay-1 fade-in-bottom">
            Permite a criação de variáveis nas comunicações e alteração de forma
            massiva, utilizando os dados dos clientes, como nome, endereço e
            outras informações base para análise.
          </p>
          <p v-scrollanimation class="delay-1 fade-in-bottom">
            Um gerenciador de comunicações é uma plataforma que otimiza o
            processo de gestão de comunicação com o cliente, cria e entrega de
            forma simplificada, comunicações digitais e utiliza templates de
            e-mails e SMS, automatiza o disparo de campanhas personalizadas,
            responsivas com alta eficiência.
          </p>
          <ul v-scrollanimation class="list square delay-2 fade-in-bottom">
            <li class="item">Opções de modelos prontos para uso (templates)</li>
            <li class="item">
              Design arrasta e solta, conceito drag-and-drop, fácil de usar
            </li>
            <li class="item">Incorporar base de dados dos clientes</li>
            <li class="item">Análise, meça e acompanhe as métricas</li>
          </ul>
          <img
            v-scrollanimation
            src="../assets/svg/solucoes/icon_gerenciamento_de_comunicacoes.svg"
            alt="Integração de Dados"
            class="adds delay-2 fade-in-bottom"
          />
        </div>
      </div>
    </section>
    <section class="section solution" id="ecossistema_repositorio_unico">
      <div class="container">
        <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">
          Repositório único (armazenamento digital)
        </h3>
        <div class="text d_flex">
          <p v-scrollanimation class="delay-1 fade-in-bottom">
            Solução de armazenamento digital com acesso seguro e online às
            comunicações para seus clientes e agentes de suporte. A solução
            armazena e recupera de forma confiável grandes volumes de
            comunicações geradas automaticamente que as empresas enviam a seus
            clientes, muitas vezes mensalmente. Pense em contas, extratos,
            documentos de apólice e muito mais. Essas comunicações exigem
            armazenamento por várias razões, incluindo gerenciamento de
            registros, auto-serviço ao cliente, reimpressões e muito mais.
          </p>
          <p v-scrollanimation class="delay-1 fade-in-bottom">
            O repositório único armazenado em cloud, comprovado e confiável, é
            usado por muitas das maiores marcas mundiais de serviços
            financeiros, seguros e outros setores para agilizar e proporcionar
            uma experiência excepcional ao cliente. É uma ferramenta amplamente
            escalável e totalmente em conformidade legislativa. A solução possui
            integração, acesso e notificações e suporta bilhões de comunicações
            online. Se integra facilmente com os seus sistemas de front e back
            office.
          </p>
          <ul v-scrollanimation class="list square delay-2 fade-in-bottom">
            <li class="item">
              Simplifique a pesquisa por tipo de documento, data ou cliente
            </li>
            <li class="item">Forneça acesso rápido a arquivos completos</li>
            <li class="item">Facilite o autoatendimento</li>
            <li class="item">Reimprima documentos sob demanda</li>
          </ul>
          <img
            v-scrollanimation
            src="../assets/svg/solucoes/icon_armazenamento_digital_repositorio_unico.svg"
            alt="Integração de Dados"
            class="adds delay-2 fade-in-bottom"
          />
        </div>
      </div>
    </section>
    <section class="section solution" id="ecossistema_mensageria">
      <div class="container">
        <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">
          Mensageria
        </h3>
        <div class="text d_flex">
          <p v-scrollanimation class="delay-1 fade-in-bottom">
            É um aplicativo com interface interativa para atualização dinâmica,
            rápida e fácil das comunicações com os clientes, atendendo ao “time
            to market” (TTM) em tempo recorde. Em algumas empresas o processo de
            criação e alteração de documentos e comunicações é muito complexo,
            passando por vários setores e torna demorado o tempo de produção.
          </p>
          <p v-scrollanimation class="delay-1 fade-in-bottom">
            Essa solução pretende reduzir as burocracias e custos acerca de
            processos longos e cansativos para alteração de comunicações com os
            clientes via SMS e Email, por exemplo. Facilitando, inclusive, a
            criação de campanhas promocionais e/ou em datas comemorativas. A
            ferramenta é intuitiva e fácil de manusear. Ainda assim, orientamos
            a equipe com um treinamento exclusivo. Todo o processo conta com um
            time de suporte 24/7 especializado para atender todas as demandas
            relacionadas a esta solução.
          </p>
          <ul v-scrollanimation class="list square delay-2 fade-in-bottom">
            <li class="item">
              Redução de processos burocráticos para alteração de documentos
            </li>
            <li class="item">
              Permite criação de comunicação para campanhas promocionais
            </li>
            <li class="item">Menor custo por alteração</li>
            <li class="item">Intuitiva e fácil de manusear</li>
          </ul>
          <img
            v-scrollanimation
            src="../assets/svg/solucoes/icon_mensageria.svg"
            alt="Integração de Dados"
            class="adds delay-2 fade-in-bottom"
          />
        </div>
      </div>
    </section>
    <section class="section solution" id="ecossistema_disparo_de_comunicacoes">
      <div class="container">
        <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">
          Disparo de Comunicações
        </h3>
        <div class="text d_flex">
          <p v-scrollanimation class="delay-1 fade-in-bottom">
            Fornecer comunicações relevantes é fundamental para uma boa
            experiência do cliente. Com tanta poluição de comunicação nos dias
            de hoje, é essencial alcançar os clientes com mensagens criadas para
            todos os dispositivos. Para além da criação dessas comunicações,
            precisamos dispará-las, bem como acompanhar o seu envio e
            recebimento.
          </p>
          <p v-scrollanimation class="delay-1 fade-in-bottom">
            O disparador de comunicações é uma ferramenta de baixo custo
            destinada a empresas de qualquer tamanho e segmento que precisam
            notificar seus clientes, prospects e/ou parceiros por um canal de
            comunicação de grande alcance. Na ferramenta também é possível
            acompanhar a entrega, taxa de abertura e falhas de envio. Com este
            controle é possível mensurar o sucesso dos envios e a efetividade de
            determinadas ações e campanhas da empresa.
          </p>
          <ul v-scrollanimation class="list square delay-2 fade-in-bottom">
            <li class="item">Baixo custo para envio</li>
            <li class="item">
              Escalabilidade – ferramenta de alta performance para fazer
              milhares de envios em segundos
            </li>
            <li class="item">Relatórios com métricas dos envios</li>
            <li class="item">
              Processo simples e rápido, intuitivo para todos os usuários
            </li>
          </ul>
          <img
            v-scrollanimation
            src="../assets/svg/solucoes/icon_disparo_de_comunicacoes.svg"
            alt="Integração de Dados"
            class="adds delay-2 fade-in-bottom"
          />
        </div>
      </div>
    </section>
    <section class="section solution" id="ecossistema_integracao">
      <div class="container">
        <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">
          Integração de Dados
        </h3>
        <div class="text d_flex">
          <p v-scrollanimation class="delay-1 fade-in-bottom">
            A integração de dados é uma solução para as empresas englobarem
            todas as informações necessárias em um único local, facilitando o
            gerenciamento dos processos produtivos. A ferramenta ajuda você
            conectar a infraestrutura do presente com a tecnologia do futuro
            para trabalhar dados da sua empresa em alta performance. O
            integrador de dados alimenta as aplicações que impulsionam a sua
            organização. Análise detalhada, aprendizagem de máquinas,
            inteligência artificial (IA), data lake e nuvem.
          </p>
          <p v-scrollanimation class="delay-1 fade-in-bottom">
            Confiabilidade e escalabilidade são alguns benefícios proporcionados
            pela solução quanto a incorporação dos dados, independentemente de
            sua fonte entre as plataformas. Elimina problemas e custos
            associados a busca de habilidades necessárias para acessar os
            sistemas empresariais normais. Quer você esteja trabalhando com
            dados de aplicações, registros ou máquinas, a solução cria
            estruturas de junção de dados que são econômicas, de alto desempenho
            e repetíveis.
          </p>
          <ul v-scrollanimation class="list square delay-2 fade-in-bottom">
            <li class="item">Validação e padronização de dados</li>
            <li class="item">
              Reconhecimento de nome e correspondência avançada
            </li>
            <li class="item">Gerenciamento de dados colaborativo</li>
            <li class="item">Conectores de CRM e ERP de qualidade de dados</li>
          </ul>
          <img
            v-scrollanimation
            src="../assets/svg/solucoes/icon_integrador_de_dados.svg"
            alt="Integração de Dados"
            class="adds delay-2 fade-in-bottom"
          />
        </div>
      </div>
    </section>
    <section class="section solution" id="ecossistema_video_interativo">
      <div class="container">
        <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">
          Vídeo interativo e personalizado
        </h3>
        <div class="text d_flex">
          <p v-scrollanimation class="delay-1 fade-in-bottom">
            Uma solução capaz de melhorar o customer experience no atendimento
            ao cliente, é o vídeo interativo. Além de personalização do projeto,
            impulsiona o engajamento do público e melhora os resultados das
            empresas.
          </p>
          <p v-scrollanimation class="delay-1 fade-in-bottom">
            Inovações em marketing, serviços e vendas, trabalham a solução para
            que as empresas proporcionem experiências satisfatórias, sem a
            necessidade de um agente humano. Converte leads em clientes e
            respondem perguntas, antes do usuário perguntar.
          </p>
          <p v-scrollanimation class="delay-1 fade-in-bottom">
            O vídeo interativo é um formato de mídia que possibilita a interação
            do usuário com o conteúdo do próprio vídeo. A sua empresa pode criar
            diversos caminhos para o cliente, incluindo links clicáveis para
            outras páginas da web.
          </p>
          <ul v-scrollanimation class="list square delay-2 fade-in-bottom">
            <li class="item">
              54% dos consumidores querem ver mais conteúdo em vídeo de uma
              marca ou empresa que eles apoiam. - Hubspot
            </li>
            <li class="item">
              Os espectadores têm 95% mais chances de lembrar de um produto
              depois de assistir a um vídeo, em comparação com a leitura em
              texto. – Forbes
            </li>
            <li class="item">
              Os profissionais de marketing de vídeo obtêm 66% mais leads
              qualificados por ano. - Optinmonster
            </li>
          </ul>
          <img
            v-scrollanimation
            src="../assets/svg/solucoes/icon_video_interativo_e_personalizado.svg"
            alt="Integração de Dados"
            class="adds delay-2 fade-in-bottom"
          />
        </div>
      </div>
    </section>
  </main>
</template>
<script>
export default {
  name: "Solutions",
};
</script>

<style lang="scss" scoped>
#solutions {
  .header {
    background-image: url("../assets/img/solucoes/solucoes_tecnologia_bg.jpg") !important;
  }

  .solution {
    .text {
      align-items: flex-start;
      flex-wrap: wrap;
      display: flex;
      justify-content: flex-start;

      p {
        display: flex;

        @include respond(md-up) {
          flex-basis: 50%;
          padding-right: 35px;
        }
      }

      img {
        
        
        @include respond(md-up) {
          max-width: 165px !important;
          margin-left: 180px;
        }

        @include respond(sm1-up) {
          max-width: 125px;
          margin-left: 15px;
        }

        @include respond(sm2-up) {
          max-width: 125px;
          margin-left: 109px;
          margin-top: 16px;
        }
      }

      ul {
        margin-top: 20px;

        @include respond(md-up) {
          flex-basis: 55%;
        }

        @include respond(sm1-up) {
          margin-bottom: 20px;
        }
      }
    }
  }

  .section {
    &:nth-child(even) {
      background-color: $DarkGrey;
    }

    &:nth-child(odd) {
      background-color: $DarkGreySecondary;

      .content {
        @include respond(md-up) {
          i.icon {
            order: 1;
          }

          .text {
            order: 0;
          }
        }
      }
    }

    .content {
      flex-wrap: wrap;
      text-align: left;

      i.icon {
        margin-bottom: 40px;
        color: $PrimaryColor;
        font-size: 80px;
        text-align: center;

        @include respond(md-up) {
          width: 30%;
          font-size: 120px;
        }
      }

      .text {
        @include respond(md-up) {
          width: 70%;
        }

        .title {
          margin-bottom: 20px;
          color: $TitleColor;
          font-size: 18px;
          text-align: center;
          text-transform: uppercase;

          @include respond(md-up) {
            text-align: left;
          }
        }

        .lists {
          justify-content: flex-start;
          flex-wrap: wrap;

          .list {
            width: 100%;

            @include respond(sm2-up) {
              width: 50%;
              padding-right: 40px;
            }
          }
        }
      }
    }
  }

  .ecossistema {
    position: relative;
    width: 100%;
    margin: 0 auto;

    @include respond(sm2-up) {
      max-width: 560px;
    }

    @include respond(md-up) {
      max-width: 600px;
    }

    @include respond(xl-up) {
      max-width: 760px;
    }

    .space {
      display: block;
      width: 100%;
    }

    .reparticoes_ecossistema {
      position: absolute;
      left: 0;
      top: 0;
      width: 41.58%;
      transition: transform 0.2s ease;
      z-index: 1;

      &:hover {
        transform: scale(1.04);
        z-index: 2;

          .img.hover{
            filter: grayscale(0) !important;
          }
        .img.hover2 {
          opacity: 1;
        }

      }

      img {
        position: relative;
        display: block;
        width: 100%;
        opacity: 1;
        transition: opacity 0.2s ease;
        z-index: 1;
        filter: grayscale(100%);
      }

      .img.hover2 {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 2;
        filter: grayscale(0);
      }

      /**
       * Site usado para criar o clip-path
       * https://bennettfeely.com/clippy/
       */

      &.centralizacao_de_comunicacoes {
        top: 0;
        left: 39.44%;
        clip-path: polygon(
          19% 1%,
          53% 3%,
          82% 15%,
          98% 26%,
          41% 98%,
          31% 100%,
          22% 93%,
          2% 4%
        );
      }

      &.gerenciamento_de_comunicacoes {
        top: 9.52%;
        left: 57.82%;
        clip-path: polygon(
          72% 18%,
          89% 43%,
          99% 71%,
          100% 95%,
          8% 95%,
          0 87%,
          0 76%,
          57% 5%
        );
      }

      &.repositorio_unico {
        top: 48.14%;
        left: 57.82%;
        clip-path: polygon(
          100% 5%,
          100% 21%,
          91% 53%,
          75% 77%,
          57% 95%,
          0 23%,
          0 12%,
          9% 5%
        );
      }

      &.mensageria {
        top: 57.77%;
        left: 39.44%;
        clip-path: polygon(
          40% 0,
          100% 74%,
          75% 91%,
          37% 100%,
          0 98%,
          20% 10%,
          27% 0
        );
      }

      &.disparo_de_comunicacoes {
        top: 55.58%;
        left: 5.85%;
        clip-path: polygon(
          80% 0,
          100% 0,
          100% 13%,
          80% 100%,
          59% 100%,
          0 53%,
          0 38%
        );
      }

      &.integrador_de_dados {
        top: 28.88%;
        left: 0.49%;
        clip-path: polygon(
          15% 0,
          100% 41%,
          100% 59%,
          15% 100%,
          10% 100%,
          0% 70%,
          0% 35%,
          10% 0
        );
      }

      &.video_interativo {
        top: 2.19%;
        left: 5.85%;
        clip-path: polygon(
          57% 0,
          80% 0,
          100% 88%,
          100% 100%,
          80% 100%,
          0 62%,
          0 48%
        );
      }
    }

    .logo-datacore {
      position: absolute;
      top: 43.71%;
      left: 45.62%;
      width: 8.75%;
    }
  }
}
</style>