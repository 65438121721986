<template>
  <div
    class="item d_flex" :style="'background-image: url(' + (require(`../../assets/img/banners/${data.img}`)) + ')'">
    <div class="container">
      <div class="text">
        <h3 class="fade-in-bottom title" v-html="data.title"></h3>
        <h3 class="fade-in-bottom title2" v-html="data.title2"></h3>
        <p class="delay-4 fade-in-bottom">{{ data.text }}</p>
        <Button :addClass="'delay-6'" :route="data.route" :label="data.button"/>
      </div>
    </div>
    <div class="effects">
      <img class="curve lg one delay-6 slide-top" src="../../assets/svg/effects/curve_lg.svg" alt= "animação curva">
      <img class="curve sm one delay-7 slide-top" src="../../assets/svg/effects/curve_sm.svg" alt= "animação curva">
      <img class="curve lg two delay-6 slide-bottom" src="../../assets/svg/effects/curve_lg_2.svg" alt= "animação curva">
      <img class="curve sm two delay-7 slide-bottom" src="../../assets/svg/effects/curve_sm_2.svg" alt= "animação curva">
      <img class="square one delay-9 slide-bottom" src="../../assets/svg/effects/squares_orange.svg" alt= "animação quadrado">
      <img class="square two delay-9 slide-top" src="../../assets/svg/effects/squares_orange.svg" alt= "animação quadrado">
      <img class="hexagon delay-9 slide-right-hex " src="../../assets/svg/effects/hexagon.svg" alt= "animação hexagonal">
    </div>
  </div>
</template>

<script>
import Button from '../common/Button.vue';

export default {
  components: { Button },
  name: 'BannerSlide',
  props: {
    data: {
      img: String,
      title: String,
      title2: String,
      text: String,
      button: String,
      route: String,
    }
  }
};
</script>

<style lang="scss" scoped>
  .item {
    position: relative;
    display: flex;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    @include respond(sm2-up) {
      justify-content: flex-start;
    }

    &::before {
      background-color: rgba(0,0,0,.7);
      z-index: 2;
    }
    .text {
      position: relative;
      color: $White;
      text-align: center;
      z-index: 3;

      @include respond(sm2-up) {
        max-width: 520px;
        margin: 0 auto;
      }
      @include respond(lg-up) {
        max-width: 580px;
      }

      .title {
        font-size: 1.6em;
        line-height: 1.2em;
        text-transform: uppercase;
        opacity: 0;
        @include respond(md-up) {
          font-size: 2em;
        }
        @include respond(lg-up) {
          font-size: 40px;
        }
      }
      .title2 {
        font-size: 1.6em;
        line-height: 1.2em;
        text-transform: uppercase;
        opacity: 0;
        @include respond(md-up) {
          font-size: 1.6em;
        }
        @include respond(lg-up) {
          font-size: 40px;
          white-space: nowrap;
          position: relative;
          right: 36px;
        }
      }
      p {
        opacity: 0;
        @include respond(md-up) {
          margin: 2em 0;
          font-size: 1.2em;
          line-height: normal;
        }
        @include respond(lg-up) {
          font-size: 20px;
        }
      }
      .button {
        margin-top: 1em;
        opacity: 0;
      }
    }

    /* Efeitos */
    .effects {
      position: absolute;
      top: 0;
      width: calc(100% - 60px);
      margin: 0 30px;
      height: 100%;
      z-index: 1;

      @include respond(sm2-up) {
        width: calc(100% - 120px);
        margin: 0 60px;
      }
      @include respond(lg-up) {
        width: 100%;
        margin: 0;
      }
      img {
        position: absolute;
        animation-duration: 2s;
        z-index: 1;

        &.curve {
          &.sm {
            width: 90%;
            @include respond(lg-up) {
              width: 55%;
            }
          }
          &.lg {
            width: 100%;
            @include respond(lg-up) {
              width: 65%;
            }
          }
          &.one {
            left: -30px;
            bottom: 0;

            @include respond(sm2-up) {
              left: -60px;
            }
            @include respond(lg-up) {
              left: 0;
            }
          }
          &.two {
            top: 0;
            right: -30px;

            @include respond(sm2-up) {
              right: -60px;
            }
            @include respond(lg-up) {
              right: 0;
            }
          }
        }
        &.square {
          width: 30px;
          @include respond(md-up) {
            width: 40px;
          }

          &.one {
            left: 0;
            bottom: 10vh;
            @include respond(lg-up) {
              left: 5%;
            }
          }
          &.two {
            right: 0;
            top: 10vh;
            @include respond(lg-up) {
              right: 5%;
            }
          }
        }
        &.hexagon {
          width: 30%;
          max-width: 180px;
          right: -60px;
          bottom: -20px;

          @include respond(sm2-up) {
            right: -100px;
          }
          @include respond(lg-up) {
            right: -70px;
            max-width: 200px;
          }
        }
      }
    }
  }
</style>