<template>
  <router-link v-if="route !== ''"
    v-scrollanimation
    :class="'button ' + (addClass ? addClass : '') + ' fade-in-bottom'"
    :to="route"
    @click="emitClick">
    {{ label }}
  </router-link>
  <button v-else
    v-scrollanimation
    :type="type"
    :class="'button ' + (addClass ? addClass : '')"
    @click="emitClick"
    :disabled="isDisabled"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    addClass: String,
    route: {
      type: String,
      default: '',
    },
    label: String,
    type: {
      type: String,
      default: 'button',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    emitClick() {
      this.$emit('clickButton');
    }
  },
};
</script>

<style lang="scss" scoped>
  .button {
    display: inline-block;
    min-width: 100px;
    padding: 0 20px;
    background-color: $PrimaryColor;
    color: $White;
    font-weight: bold;
    line-height: 40px;
    text-align: center;
    border: 0;
    outline: 0;
    font-size: 14px;
    cursor: pointer;
    @include border-radius(5px);
    @include box-shadow(0 4px 4px rgba(0,0,0,.15));
    @include transition(all .3s ease);

    &:hover {
      background-color: $White;
      color: $PrimaryColor;
    }
    
    &.outline {
      background-color: transparent;
      color: $PrimaryColor;
      border: 1px solid $PrimaryColor;
      @include box-shadow(none);
      &:hover {
        background-color: $PrimaryColor;
        color: $White;
      }
    }

    &.cookie {
      margin-top: 0;

      &:hover {
        background-color: $DarkGrey;
        color: $White;
      }
    }

    &:disabled {
      background-color: $InputBackground;
      color: #595959;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
</style>