<template>
  <main class="home">
    <Banner/>
    <AboutSection/>
    <NumbersSection/>
    <ServiceSection/>
    <TextEmphasis
      :imgBg="'bg-frase'"
      :text="'Grandes coisas nos negócios nunca são feitas por uma pessoa. <span>Elas são feitas por um time de pessoas.</span>'"
    />
    <ComunicationSection/>
    <ProductSection/>
    <CaseSection/>
  </main>
</template>

<script>
import Banner from '../components/banner/Banner.vue'
import AboutSection from '../components/about/AboutSection.vue'
import NumbersSection from '../components/numbers/NumbersSection.vue'
import ServiceSection from '../components/services/ServiceSection.vue'
import TextEmphasis from '../components/common/TextEmphasis.vue'
import ComunicationSection from '../components/comunication/ComunicationSection.vue'
import ProductSection from '../components/product/ProductSection.vue'
import CaseSection from '../components/caseStudy/CaseSection.vue'

export default {
  name: 'Home',
  components: {
    Banner,
    AboutSection,
    NumbersSection,
    ServiceSection,
    TextEmphasis,
    ComunicationSection,
    ProductSection,
    CaseSection,
  },
  data() {
    return {
      textPhraseOne: 'Somente uma pessoa não constroi produtos incríveis no mundo dos negócios. Um time sim.',
      classLight: 'light',
      classDark: 'dark',
      classOrange: 'orange',
      classGradient: 'gradient',
    };
  }
 }
</script>
