<template>
  <div class="group">
    <label :for="name" :class="seeLabel ? '' : 'hide'">{{ label }}</label>
    <textarea
      :id="name"
      :name="name"
      @focus="hideLabel()"
      @blur="showLabel($event); updateValue($event.target.value);">
    </textarea>
    <div v-show="isValidated && errorMessage" class="error">
        <i class="icon icon-x"></i>
        <p>{{ errorMessage }}</p>
      </div>
      <div v-show="isValidated && !errorMessage" class="success"><i class="icon icon-check"></i></div>
  </div>
</template>
<script>
export default {
  name: 'Textarea',
  props: {
    name: {
      type: String,
      required: true,
    },
    label: String,
    isValidated: {
      type: Boolean,
      default: false,
    },
    errorMessage: String,
  },
  data() {
    return {
      seeLabel: true,
    };
  },
  methods: {
    updateValue (value) {
      this.value = value;
      this.$emit('setValue', this.value);
    },
    hideLabel() {
      this.seeLabel = false;
    },
    showLabel(e) {
      if (e.currentTarget.value === '') {
        this.seeLabel = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .group {
    position: relative;
    width: calc(100% - 20px);
    margin: 0 10px 20px;
    
    label {
      position: absolute;
      left: 15px;
      top: 20px;
      color: $InputColor;
      opacity: .5;
      @include transform(translateY(-50%));
      @include transition(all .3s ease);

      &.hide {
        position: absolute;
        top: 11px;
        font-size: 13px;
        pointer-events: none;
      }

      @include respond(md-up) {
        top: 28px;
      }
    }

    textarea {
      display: block;
      width: 100%;
      height: 140px;
      padding: 15px;
      background-color: $InputBackground;
      color: $InputColor;
      font-size: 1em;
      resize: none;
      border: 0;
      outline: 0;
      @include border-radius(5px);

      @include respond(md-up) {
        height: 200px;
        padding: 20px 15px;
      }
    }

    .error {
      color: #a62e2e;
    }
    
    .success {
      color: #36ca1c;
    }

    .icon {
      position: absolute;
      top: 0;
      right: 20px;
      font-size: 24px;
      line-height: 50px;
      @include respond(md-up) {
        line-height: 70px;
      }
    }

    p {
      display: block;
      margin: 0;
      font-size: 12px;
      line-height: normal;
      text-align: right;
    }
  }
</style>