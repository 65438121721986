<template>
  <section id="about" class="section">
    <div class="container">
      <div :class="'illustration' + (isMobile ? '' : ' d_flex')">
        <div v-if="!isMobile" class="image">
          <img
            v-scrollanimation
            class="img delay-4 slide-left"
            src="../../assets/img/bg-about.webp"
            alt="Dois homens conversando"
            width="671"
            height="729"
          />
        </div>
        <div class="text">
          <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">
            Quem somos
          </h3>
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            Há 5 anos no mercado nacional, a Data Core Solutions é uma empresa
            de tecnologia com foco no CCM, CX e outras soluções de integração de
            sistemas em plataformas Java, .Net, entre outras.
          </p>
          <p v-scrollanimation class="delay-2 fade-in-bottom">
            O mercado está exigindo transformações nos processos das empresas de
            forma urgente e o nosso objetivo é facilitar a vida das pessoas por
            meio do uso da tecnologia de maneira simples e com a maior
            abrangência possível e ajudar nossos clientes a tirar o melhor
            proveito dos seus sistemas.
          </p>
          <div v-scrollanimation class="adds delay-2 fade-in-bottom">
            <Button
              :addClass="'delay-2'"
              :route="'/sobre'"
              :label="'Conheça a Data Core '"
            />
            <img
              src="../../assets/img/logo-datacoress-simbolo.webp"
              alt="Logo símbolo DataCore"
              width="105"
              height="151"
            />
          </div>
        </div>
      </div>
    </div>
    <Effects :invert="false" />
  </section>
</template>
<script>
import Button from "../common/Button.vue";
import Effects from "../common/Effects.vue";
export default {
  components: { Button, Effects },
  name: "AboutSection",
};
</script>

<style lang="scss" scoped>
#about {
  background-color: $DarkGrey;
  @include respond(md-up) {
    text-align: left;
  }
  .illustration {
    align-items: flex-start;
    @include respond(md-up) {
      margin: 0 -50px;
    }
    .image,
    .text {
      @include respond(md-up) {
        flex-basis: calc(50% - 100px);
        margin: 0 50px;
      }
    }

    .image {
      position: relative;

      img {
        @include border-radius(5px);
      }
    }

    .text {
      p {
        max-width: 700px;
        margin: 0 auto 40px;
        opacity: 0;
      }
      .adds {
        
        display: flex;
        justify-content: space-around; 
        align-items: center;

        @include respond(sm1-up) {
            flex-direction: column;
          }

        @include respond(sm2-up) {
            display: flex;
            justify-content: space-around; 
            align-items: center;
          }

        img {
          max-width: 80px;
          
          @include respond(sm1-up) {
            margin-top: 30px;
          }

          @include respond(md-up) {
            max-width: 80px;
          }
        }
      }
    }
  }
}
</style>