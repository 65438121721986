<template>
  <main id="services">
    <header class="header">
      <div class="container">
        <h1 v-scrollanimation class="title delay-1 fade-in-bottom">Serviços</h1>
        <p v-scrollanimation class="delay-2 fade-in-bottom">
          Tecnologia e compromisso com a sua empresa.
        </p>
      </div>
    </header>
    <section class="section management">
      <div class="container">
        <div class="content d_flex">
          <img
            v-scrollanimation
            src="../assets/svg/icon_suporte_tecnico_servicos_home.svg"
            alt="Suporte Técnico"
            class="icon support img delay-2 fade-in-bottom"
          />
          <div class="text support">
            <h3 v-scrollanimation class="title delay-1 fade-in-bottom">
              Suporte Especializado 24/7
            </h3>
            <p v-scrollanimation class="delay-2 fade-in-bottom">
              O suporte especializado da Data Core Solutions, oferece um pacote
              de serviços de suporte com profissionais disponíveis: 8x5 (8 horas
              por dia, 5 dias na semana) ou 24x7 (24 horas por dia, 7 dias na
              semana). <br />Um ponto muito importante do suporte é o SLA
              (Service Level Agreement), com tempo médio de atendimento conforme
              criticidade de suas demandas. <br />Realizamos atendimento remoto
              e/ou on-site (conforme necessidade) garantindo assim qualidade e
              melhor funcionamento do sistema produtivo. <br />Os profissionais
              são treinados e instruídos a realizarem análises preventivas
              evitando acúmulo de incidentes e impacto no ambiente produtivo do
              cliente. Todos preparados e especializados para atuar com soluções
              como Remedy, Jira, entre outras. Entre as vantagens do
              suporte temos o atendimento em português, inglês e espanhol;
              mentoring sobre o uso das soluções usadas por nossos clientes,
              além de realizarmos o monitoramento do ambiente do cliente a fim
              de efetuar atendimentos proativos e também indicar planos de
              melhorias.
            </p>
            <p v-scrollanimation class="delay-2 fade-in-bottom">
              <br /><br />O atendimento é dividido de três formas:
            </p>
            <div
              v-scrollanimation
              class="lists support d_flex delay-2 fade-in-bottom"
            >
              <ul class="list square">
                <li class="item title list">Suporte Funcional</li>
                <p>
                  Atua na análise de reclamações com base em levantamento de
                  configurações funcionais para resolver os problemas por meio
                  de ajustes de configurações via interfaces de atendimento.
                </p>
                <li class="item title list">Suporte Especializado</li>
                <p>
                  Atua no diagnóstico e na detecção de falhas, para prover
                  solução definitiva aos lapsos com configurações ou ações
                  operacionais na aplicação, ou solução de contorno pelas mesmas
                  vias quando não puder detectar a causa-raiz da falha.
                </p>
                <li class="item title list">
                  Suporte em nível de desenvolvimento
                </li>
                <p>
                  Atua no diagnóstico e na detecção de falhas de software e
                  identifica a causa-raiz para solucionar definitivamente a
                  falha através da correção do código do software que causa o
                  desequilíbrio.
                </p>
              </ul>
            </div>
          </div>
        </div>
        <div class="content support d_flex">
          <img
            v-scrollanimation
            src="../assets/svg/icon_caso_de_sucesso_suporte_especializado.svg"
            alt="Suporte Técnico Sucesso"
            class="icon support sucess img delay-2 fade-in-bottom"
          />
          <div class="text supportSucess">
            <h3 v-scrollanimation class="title delay-1 fade-in-bottom">
              Caso de Sucesso do Suporte Especializado 24/7
            </h3>
            <h3 v-scrollanimation class="title support delay-2 fade-in-bottom">
              Suporte ao Projeto Fatura Online na Telefônica Vivo
            </h3>
            <p v-scrollanimation class="delay-2 fade-in-bottom">
              As ações de melhorias e correções implantadas pelo time de suporte
              da Data Core Solutions no ambiente da Telefónica de 2019 até hoje,
              apresentou:
            </p>
            <div
              v-scrollanimation
              class="text lists support delay-2 fade-in-bottom"
            >
              <ul v-scrollanimation class="list square delay-2 fade-in-bottom">
                <li class="item">
                  Redução do número de erros/acionamentos em 66,98%;
                </li>
                <li class="item">
                  Redução do tempo de geração de faturas em 57,3%;
                </li>
                <li class="item">Redução do tempo de envio em 50%;</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section management">
      <div class="container">
        <div class="content d_flex">
          <i
            v-scrollanimation
            class="icon icon-management outsourcing delay-2 fade-in-bottom"
          ></i>
          <div class="text outsourcing">
            <h3 v-scrollanimation class="title delay-1 fade-in-bottom">
              Consultor/outsourcing
            </h3>
            <p v-scrollanimation class="delay-2 fade-in-bottom">
              Alocação temporária de profissionais multidisciplinares e
              altamente capacitados para entender as necessidades e as
              ferramentas tecnológicas da empresa, proporcionando ganho e
              melhoria contínua de processos. <br>Mapeamentos e entrevistas dos
              processos AS-IS e demais gaps, identificando problemas e propondo
              soluções. <br> Desenhamos os conceitos AS IS x TO BE que irão otimizar e
              automatizar os processos com uma implantação de baixo risco e
              custo.
            </p>
            <div v-scrollanimation class="lists d_flex delay-2 fade-in-bottom">
              <ul class="list square">
                <li class="item">
                  Suporte técnico e capacitação nos processos
                </li>
                <li class="item">Mapeamento de gaps e busca de soluções</li>
                <li class="item">Processos com baixo risco e custo</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section management">
      <div class="container">
        <div class="content d_flex">
          <i
            v-scrollanimation
            class="icon icon-planning software delay-2 fade-in-bottom"
          ></i>
          <div class="text">
            <h3 v-scrollanimation class="title delay-1 fade-in-bottom">
              Fábrica de Software
            </h3>
            <p v-scrollanimation class="delay-2 fade-in-bottom">
              Entrega soluções de TI e Desenvolvimento de Sistemas para vencer
              desafios da transformação digital com agilidade, soluções
              sustentáveis e qualidade. Equipe qualificada com foco em
              desenvolvimento de funcionalidades que utilizam o que há de mais
              moderno em linguagens de programação, gestão de projetos de
              sucesso alinhado às características e demandas das empresas,
              automação de processos e integração de sistemas.
            </p>
            <div v-scrollanimation class="text lists delay-2 fade-in-bottom">
              <h3 v-scrollanimation class="title list delay-1 fade-in-bottom">
                Automação de Processos
              </h3>
              <p v-scrollanimation class="first-child delay-2 fade-in-bottom">
                Elevar o desempenho da sua empresa, requer a melhoria na
                automação de processos, para resultados mais rápidos, confiáveis
                e seguros, além de melhorar a produtividade nas atividades
                ligadas ao core business da empresa.
              </p>
              <ul v-scrollanimation class="list square delay-2 fade-in-bottom">
                <li class="item">Otimização de processos</li>
                <li class="item">Excelência no atendimento ao cliente</li>
                <li class="item">Reduz custos aos negócios</li>
                <li class="item">Funcionalidades customizáveis</li>
                <li class="item">Permite a padronização das tarefas</li>
                <li class="item">Otimiza o tempo e aumenta a produtividade</li>
                <li class="item">Facilita o acesso à informação</li>
              </ul>
            </div>
            <div v-scrollanimation class="text lists delay-2 fade-in-bottom">
              <h3 v-scrollanimation class="title list delay-1 fade-in-bottom">
                Integração de Sistemas
              </h3>
              <p v-scrollanimation class="delay-2 fade-in-bottom">
                Através da integração de sistemas, é possível promover uma
                grande melhoria no desempenho e na organização das operações,
                redução de custos, diminuição da burocracia e eliminação de gaps
                operacionais.
              </p>
              <ul v-scrollanimation class="list square delay-2 fade-in-bottom">
                <p>Alguns dos benefícios da ferramenta:</p>
                <li class="item">Redução de custos</li>
                <li class="item">Melhor desempenho</li>
                <li class="item">Otimização de processos</li>
                <li class="item">Maior integração entre os setores</li>
                <li class="item">Gestão inteligente do estoque</li>
                <li class="item">Funcionalidades customizáveis</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
export default {
  name: "Services",
};
</script>

<style lang="scss" scoped>
#services {
  .section {
    &:nth-child(even) {
      background-color: $DarkGrey;
    }
    &:nth-child(odd) {
      background-color: $DarkGreySecondary;
    }

    .content {
      @include respond(sm2-up) {
        .icon {
          order: 1;
        }
        .text {
          order: 0;
        }
      }
    }

    .content {
      flex-wrap: wrap;
      text-align: justify;
      align-items: flex-start;

      .icon {
        margin-bottom: 40px;
        color: $PrimaryColor;
        font-size: 80px;
        text-align: center;
        width: calc(20%);

        @include respond(md-up) {
          font-size: 120px;
        }

        @include respond(sm1-up) {
          width: 100%;
        }

        &.outsourcing {
          order: 0;
        }

        &.support {
          @include respond(md-up) {
            width: 23%;
            padding: 40px 56px !important;
          }

          @include respond(sm2-up) {
            width: 26% !important;
            padding: 38px 0 0 45px;
          }

          @include respond(sm1-up) {
            max-width: 130px;
          }

          &.sucess {
            order: 0;
            max-width: 190px;

            @include respond(md-up) {
              width: 23%;
              padding: 40px 56px !important;
            }

            @include respond(sm2-up) {
              padding-right: 45px;
            }

            @include respond(sm1-up) {
              max-width: 80px;
            }
          }
        }
      }
      &.support {
        justify-content: flex-start;
        padding: 0 20px 0 0;

        @include respond(sm1-up) {
            justify-content: center;
          }
      }

      .text {
        @include respond(sm1-up) {
          width: 100%;
        }

        @include respond(sm2-up) {
          width: calc(74%);
          float: left;
        }

        &.supportSucess {
          @include respond(sm2-up) {
            width: calc(59%);
            float: left;
          }
        }

        .title {
          margin-bottom: 15px;
          color: $PrimaryColor;
          font-size: 18px;
          text-align: center;
          text-transform: uppercase;

          @include respond(md-up) {
            text-align: left;
          }

          &.list {
            font-weight: bold;
            font-size: 16.5px;
            color: $TitleColor;

            @include respond(sm2-up) {
              text-align: left;
            }

            &.support {
              padding: 0;
            }
          }
          &.support {
            margin-bottom: 0;
            font-size: 14px;
            color: $LightGrey;
            text-transform: uppercase;
          }
        }

        .lists {
          padding-top: 50px;
          width: calc(50%);

          &.support {
            width: 100%;
            padding-top: 0;
          }

          @include respond(sm1-up) {
            float: none;
            width: 100%;
          }

          .first-child {
            @include respond(sm2-up) {
              padding-right: 30px;
            }
          }

          .list {
            width: 100%;

            @include respond(sm1-up) {
              width: 100%;
            }

            p {
              text-align: justify;
            }
          }
        }

        &.support {
          margin-bottom: 70px;
        }
      }
    }
  }
}
</style>