<template>
  <section id="services" class="section">
    <div class="container">
      <h3 v-scrollanimation class="title_section delay-2 fade-in-bottom">Serviços</h3>
      <ServiceCard />
      <Button :route="'/servicos'" :label="'Descubra mais!'"/>
    </div>
    <Effects :invert="false" />
  </section>
</template>

<script>
import Button from '../common/Button.vue'
import Effects from '../common/Effects.vue'
import ServiceCard from './ServiceCard.vue'

export default {
  name: 'ServiceSection',
  components: {
    ServiceCard,
    Button,
    Effects,
  },
}
</script>

<style lang="scss" scoped>
  #services {
    background-color: $DarkGreySecondary;

    .container {
      @include respond(lg-up) {        
        .button {
          margin-top: 80px;
        }
      }
    }
  }
</style>
